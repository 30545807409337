import { Divider, IconButton, Stack, SxProps, useTheme } from '@mui/material';
import React, { ReactElement, useContext } from 'react';
import { UserContext } from 'components/UserContext';
import { useFeatures } from 'components/FeaturesContext';
import { NavItemButton } from 'components/NavItemButton/NavItemButton';
import {
  MenuBook,
  People,
  School,
  Settings,
  Face,
  Help,
  Speed,
  AppRegistration,
  Edit,
  DataUsage,
  CollectionsBookmarkOutlined,
  SettingsOutlined,
  ContactSupportOutlined,
  AttachMoney,
  Store,
} from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { OrganizationSelect } from 'components/OrganizationSelect/OrganizationSelect';
import { Row } from 'components/Row/Row';
import { useIntercom } from 'react-use-intercom';
import { Link } from 'react-router-dom';
import { KyronLogo } from 'components/KyronLogo';
import { KyronTooltip } from '../KyronTooltip';

export const STUDIO_NAV_WIDTH = 320;
export const STUDIO_NAV_WIDTH_COLLAPSED = 84;
export const TRANSITION_STYLE = '0.2s ease-in-out';

const getAnimationStyles = (collapsed: boolean) => ({
  transition: `width ${TRANSITION_STYLE}`,
  width: `${collapsed ? STUDIO_NAV_WIDTH_COLLAPSED : STUDIO_NAV_WIDTH}px`,
});

type Props = {
  sx?: SxProps;
  collapsed: boolean;
  onCollapse: () => void;
  isMobile?: boolean;
};

type NavItem = {
  to?: string;
  onClick?: () => void;
  label?: string;
  icon?: ReactElement;
  spacing?: number;
};

export function StudioNavigation({ sx, collapsed, onCollapse, isMobile }: Props) {
  const { user, isOrgAdmin, isPlatformAdmin } = useContext(UserContext);
  const theme = useTheme();
  const { showSpace } = useIntercom();
  const { new_plg_flow: isNewPlgFlow, show_classrooms: showClassrooms } = useFeatures();

  const commonNavItems: NavItem[] = [
    { to: '/studio/courses', label: 'Courses', icon: <CollectionsBookmarkOutlined /> },
    ...(isNewPlgFlow && !showClassrooms ? [] : [{ to: '/studio/classrooms', label: 'Classrooms', icon: <School /> }]),
    ...(isNewPlgFlow // TODO: cleanup after moving all users to new PLG flow
      ? []
      : [{ to: '/marketplace', label: 'Marketplace', icon: <Store /> }]),
  ];

  const orgAdminNavItems: NavItem[] = [
    { to: '/studio/settings', label: 'Settings & members', icon: <SettingsOutlined /> },
  ];

  const supportNavItems: NavItem[] = [
    { onClick: () => showSpace('messages'), label: 'Contact support', icon: <ContactSupportOutlined /> },
    { to: '/pricing', label: 'Pricing', icon: <AttachMoney /> },
  ];

  const platformAdminNavItems: NavItem[] = [
    { spacing: 2 },
    { to: '/studio/tutors', label: 'Tutors', icon: <Face /> },
    { to: '/studio/internal_lesson_editor', label: 'Internal Lesson Editor', icon: <Edit /> },
    { to: '/studio/misconception_editor', label: 'Misconception Editor', icon: <Help /> },
    { to: '/studio/course_editor', label: 'Course Editor', icon: <AppRegistration /> },
    { to: '/studio/unit_editor', label: 'Unit Editor', icon: <Edit /> },
    { to: '/studio/test_runner', label: 'Test Runner', icon: <Speed /> },
    { to: '/studio/organization_analytics', label: 'Organization Analytics', icon: <DataUsage /> },
    { to: '/studio/student_dashboard', label: 'Student Dashboard', icon: <People /> },
  ];

  const navItems: NavItem[] = [
    ...commonNavItems,
    { spacing: 2 },
    ...(isOrgAdmin ? orgAdminNavItems : []),
    ...supportNavItems,
    ...(isPlatformAdmin ? platformAdminNavItems : []),
  ];

  return isMobile ? (
    <Row
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'surfaceContainer.main',
        height: '80px',
        px: 1,
        zIndex: 99,
        typography: 'labelSmall',
        gap: 2,
      }}
    >
      <NavItemButton to='/studio/library' label='Library' icon={<MenuBook />} vertical />
      {isNewPlgFlow && showClassrooms ? null : (
        <NavItemButton to='/studio/classrooms' label='Classrooms' icon={<School />} vertical />
      )}
      {/* TODO(derick): cleanup after moving all users to new PLG flow */}
      {isNewPlgFlow ? null : <NavItemButton to='/marketplace' label='Marketplace' icon={<Store />} vertical />}
      {isOrgAdmin && <NavItemButton to='/studio/settings' label='Settings' icon={<Settings />} vertical />}
    </Row>
  ) : (
    <Stack
      spacing={5}
      padding='32px 16px'
      sx={{ ...sx, ...getAnimationStyles(collapsed) }}
      data-testid='studio-sidebar'
    >
      <Link to='/' aria-label='Home'>
        <KyronLogo fill={theme.palette.primary.main} />
      </Link>

      {user ? <OrganizationSelect isIconButton={collapsed} /> : null}

      <Stack
        gap={1}
        component='nav'
        sx={{ typography: 'labelMedium', color: 'text.secondary', overflowX: 'hidden', overflowY: 'auto' }}
      >
        {/* NAVIGATION LINKS */}
        {navItems.map((props, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          if ('spacing' in props) return <Divider key={idx} sx={{ my: props.spacing }} />;

          const { onClick, icon, label, ...itemProps } = props;

          return (
            <NavItemButton
              collapsed={collapsed}
              key={label}
              label={label}
              icon={icon}
              onClick={onClick}
              {...itemProps}
            />
          );
        })}
      </Stack>

      <CollapseButton collapsed={collapsed} onCollapse={onCollapse} />
    </Stack>
  );
}

function CollapseButton({ collapsed, onCollapse }: { collapsed: boolean; onCollapse: () => void }) {
  return (
    <KyronTooltip title={collapsed ? 'Expand Menu' : 'Collapse Menu'} placement='right' arrow>
      <IconButton
        data-testid='collapse-button'
        onClick={onCollapse}
        sx={{
          position: 'absolute',
          bottom: 80,
          left: (collapsed ? STUDIO_NAV_WIDTH_COLLAPSED : STUDIO_NAV_WIDTH) - 14,
          zIndex: 99999,
          transition: `left ${TRANSITION_STYLE}`,
          width: 24,
          height: 24,
          background: t => t.palette.surfaceContainer.main,
        }}
      >
        <KeyboardArrowLeftIcon fontSize='small' sx={{ transform: `rotate(${collapsed ? 180 : 0}deg)` }} />
      </IconButton>
    </KyronTooltip>
  );
}
