/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TOCRow } from './TOCRow';
import { sortByPosition, moveObject } from '../utils/arrayUtils';

const styles = {
  TOC: {
    marginTop: '10px',
  },
};

export const TableOfContentsBuilder = props => {
  const updateSegmentTOC = (event, id) => {
    const tableOfContent = props.tableOfContents.find(toc => toc.id === id);
    if (event === 'indent') {
      tableOfContent.position += 0.1;
    } else if (event === 'unindent') {
      tableOfContent.position = Math.floor(tableOfContent.position);
    } else if (event === 'move up') {
      movePosition(tableOfContent, 'up');
      return;
    } else if (event === 'move down') {
      movePosition(tableOfContent, 'down');
      return;
    } else {
      const { name, value } = event.target;
      if (tableOfContent) {
        tableOfContent[name] = value;
      }
    }
    const newTableOfContents = props.tableOfContents.filter(toc => toc.id !== id);
    newTableOfContents.push(tableOfContent);
    props.updateTOC(newTableOfContents);
  };

  const addToTocs = (toc, tocs) => {
    const newTOCs = tocs.filter(t => t.id !== toc.id);
    newTOCs.push(toc);
    return newTOCs;
  };

  const getChildren = tableOfContent => {
    // get all the children of a table of content
    // find the next row that is not indented
    const followingNonIndentedTOC = props.tableOfContents.find(
      toc => toc.position > tableOfContent.position && toc.position % 1 === 0,
    );
    let children = [];
    if (followingNonIndentedTOC) {
      // if there is another non-indented row, the 'children' are all the TOCs in between the two non-indented rows
      children = props.tableOfContents.filter(
        toc =>
          toc.position > tableOfContent.position &&
          toc.position < followingNonIndentedTOC.position &&
          toc.position % 1 !== 0,
      );
    } else {
      // if there are no more non-indented rows, the children are the rest of the TOCs
      children = props.tableOfContents.filter(toc => toc.position > tableOfContent.position && toc.position % 1 !== 0);
    }
    return children;
  };

  const deleteRow = (tableOfContent, id) => {
    const finalRow = props.tableOfContents.length === 1;
    if (finalRow) {
      const newTableOfContents = props.tableOfContents.filter(toc => toc.id !== id);
      newTableOfContents.push({ id: 1, position: 1.0, label: '', lesson_segment_id: '', lesson_id: props.lesson.id });
      props.updateTOC(newTableOfContents);
      return;
    }
    const indented = tableOfContent.position % 1 !== 0;
    const newTableOfContents = props.tableOfContents.filter(toc => toc.id !== id);
    if (indented) {
      const laterChildren = getChildren(tableOfContent);
      laterChildren.forEach(child => {
        // eslint-disable-next-line no-param-reassign
        child.position = Math.round((child.position - 1) * 10) / 10;
        newTableOfContents.filter(toc => toc.id !== child.id).push(child);
      });
    }
    props.updateTOC(newTableOfContents);
  };

  function movePosition(tableOfContent, direction) {
    const indented = tableOfContent.position % 1 !== 0;
    const newTOCs = props.tableOfContents;
    if (indented) {
      const parent = props.tableOfContents
        .reverse()
        .find(toc => toc.position < tableOfContent.position && toc.position % 1 === 0);
      props.tableOfContents.reverse();
      let equals = getChildren(parent);
      const unit = equals.find(toc => toc.id === tableOfContent.id);
      equals = moveObject(unit, equals, direction);
      equals.forEach(equal => {
        // eslint-disable-next-line no-param-reassign
        equal.position = equals.indexOf(equal) + 1.1 + parent.position;
        addToTocs(equal, newTOCs);
      });
    } else {
      let equals = [];
      const nonIndenteds = props.tableOfContents.filter(toc => toc.position % 1 === 0);
      nonIndenteds.forEach(nonIndented => {
        equals.push({ id: nonIndented.id, toc: nonIndented, children: getChildren(nonIndented) });
      });
      const unit = equals.find(equal => equal.id === tableOfContent.id);
      equals = moveObject(unit, equals, direction);
      const positionArray = [];
      equals.forEach(equal => {
        positionArray.push(equal.toc.position);
        // eslint-disable-next-line no-param-reassign
        equal.toc.position = positionArray.indexOf(equal.toc.position) + 1;
        addToTocs(equal.toc, newTOCs);
        equal.children?.forEach(child => {
          positionArray.push(child.position);
          // eslint-disable-next-line no-param-reassign
          child.position = positionArray.indexOf(child.position) + 1.1;
          addToTocs(child, newTOCs);
        });
      });
    }
    props.updateTOC(newTOCs);
  }

  const handleAddRow = () => {
    let maxPosition = 0;
    let maxId = 0;
    props.tableOfContents.forEach(toc => {
      if (toc.id > maxId) {
        maxId = toc.id;
      }
      if (toc.position > maxPosition) {
        maxPosition = toc.position;
      }
    });
    props.setTableOfContents([
      ...props.tableOfContents,
      {
        lesson_id: props.lesson.id,
        lesson_segment_id: '',
        label: '',
        position: maxPosition + 1,
        id: maxId + 1,
      },
    ]);
  };

  const getLastTOC = () => {
    const { length } = props.tableOfContents;
    return props.tableOfContents[length - 1];
  };

  const sortedTOC = tocs => {
    sortByPosition(tocs);
    return tocs;
  };

  return props.showTOC ? (
    <Accordion data-testid='lesson-toc-accordion' style={styles.TOC}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} data-testid='toc-header'>
        <Typography>Build Table of Contents</Typography>
      </AccordionSummary>
      <AccordionDetails data-testid='toc-details'>
        <Grid container spacing={2}>
          {sortedTOC(props.tableOfContents).map(tableOfContent => (
            <Grid item xs={12} key={tableOfContent.id} data-testid='toc-row'>
              <TOCRow
                tableOfContent={tableOfContent}
                tableOfContents={props.tableOfContents}
                updateSegmentTOC={updateSegmentTOC}
                deleteRow={deleteRow}
                handleAddRow={handleAddRow}
                last={getLastTOC()}
                lesson={props.lesson}
                allSegmentNamesIds={props.segmentNamesIds}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
};

/* eslint-disable react/forbid-prop-types */
TableOfContentsBuilder.propTypes = {
  lesson: PropTypes.object.isRequired,
  tableOfContents: PropTypes.array,
  setTableOfContents: PropTypes.func,
  showTOC: PropTypes.bool,
  segmentNamesIds: PropTypes.array,
  updateTOC: PropTypes.func,
};
