/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { KyronClient } from '../components/utils/KyronClient';
import { LessonSegment, LessonValidation, LessonPayload, Lesson, LessonDetails } from './types';

export async function getActiveLessons(): Promise<{ lessons: Lesson[] }> {
  const client = new KyronClient();
  const lessons = (await client.getDataWithError('api/v1/lessons?active=true')) as { lessons: Lesson[] };
  return lessons;
}

export async function getLessons(): Promise<{ lessons: Lesson[] }> {
  const client = new KyronClient();
  const lessons = (await client.getDataWithError('/api/v1/lessons')) as { lessons: Lesson[] };
  return lessons;
}

export async function getLesson(lessonId: number): Promise<Lesson> {
  const client = new KyronClient();
  const lesson = (await client.getDataWithError(`/api/v1/lessons/${lessonId}`)) as Lesson;
  return lesson;
}

export async function updateLesson(lesson: LessonPayload): Promise<Lesson> {
  const formData = new FormData();
  for (const property in lesson) {
    const key = property as keyof LessonPayload;
    if (key === 'table_of_contents' || key === 'editors') {
      lesson[key]?.forEach(item => {
        formData.append(`${property}[]`, JSON.stringify(item));
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const val = lesson[key] as any;
      formData.append(property, val);
    }
  }

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/lessons/${lesson.id}`, 'PUT', formData);
}

export async function deleteLesson(lessonId: number): Promise<Lesson> {
  const client = new KyronClient();
  return client.deleteDataWithError(`/api/v1/lessons/${lessonId}`);
}

export async function getLessonBlob(lessonId: number): Promise<{ fileName: string; blob: Blob }> {
  const client = new KyronClient();
  const data = (await client.getDataWithError(`/api/v1/lessons/${lessonId}/export`)) as {
    name: string;
    [key: string]: unknown;
  };
  const fileName = `${data.name}.json`;
  const blob = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });
  return { fileName, blob };
}

export async function finalizeLesson(lessonId: number): Promise<Lesson> {
  const client = new KyronClient();
  return await client.submitJsonDataWithError(`/api/v1/lessons/${lessonId}/finalize`, 'POST', new FormData());
}

export async function publishLesson(lessonId: number): Promise<Lesson> {
  const client = new KyronClient();
  return await client.submitJsonDataWithError(`/api/v1/lessons/${lessonId}/publish`, 'POST', new FormData());
}

export async function getLessonSegments(lessonId: number): Promise<LessonSegment[]> {
  const client = new KyronClient();
  const { lesson_segments: lessonSegments } = (await client.getDataWithError(
    `/api/v1/lessons/${lessonId}/lesson_segments`,
  )) as {
    lesson_segments: LessonSegment[];
  };
  return lessonSegments;
}

export async function getLessonDetails(lessonId: number): Promise<LessonDetails> {
  const client = new KyronClient();
  return await client.getDataWithError<LessonDetails>(`/api/v1/lessons/${lessonId}/lesson_details`);
}

export async function validateLesson(lessonId: number): Promise<LessonValidation[]> {
  const client = new KyronClient();
  return await client.getDataWithError<LessonValidation[]>(`/api/v1/lessons/${lessonId}/validate`);
}
