import { KyronClient } from '../components/utils/KyronClient';
import { LessonContainer } from './types';

export async function getMathLessonContainers(): Promise<LessonContainer[]> {
  const client = new KyronClient();

  const path = `/api/v1/lesson_containers?math=true`;
  return await client.getDataWithError<LessonContainer[]>(path);
}

export async function getPublicLessonContainers(): Promise<LessonContainer[]> {
  const client = new KyronClient();

  const path = `/api/v1/lesson_containers`;
  return await client.getDataWithError<LessonContainer[]>(path);
}

export async function getMarketplaceLessonContainers(): Promise<LessonContainer[]> {
  const client = new KyronClient();

  const path = '/api/v1/lesson_containers?marketplace=true';
  return await client.getDataWithError<LessonContainer[]>(path);
}

export async function getDemoLessonContainers(): Promise<LessonContainer[]> {
  const client = new KyronClient();

  const path = `/api/v1/lesson_containers?demo=true`;
  return await client.getDataWithError<LessonContainer[]>(path);
}
