import { DeleteForever } from '@mui/icons-material';
import { List, ListItem, IconButton, ListItemText } from '@mui/material';
import React, { useContext } from 'react';
import { FileUploaderContext } from './FileUploader';

export function FileList({ onRemove }: { onRemove: (fileName: string) => void }) {
  const uploaderCtx = useContext(FileUploaderContext);

  const removeFile = (signedId?: string) => {
    if (!signedId) {
      console.warn("Can't remove file from state. signedId is undefined");
      return;
    }
    // update FileUploader internal state
    uploaderCtx?.removeFile(signedId);
    // Invoke on remove callback to reflect file list changes to parent
    onRemove(signedId);
  };

  return (
    <List>
      {uploaderCtx?.files?.map(file => (
        <ListItem key={file.signed_id}>
          <IconButton onClick={() => removeFile(file.signed_id)} sx={{ mr: 1 }} data-testid='delete-file'>
            <DeleteForever color='error' />
          </IconButton>
          <ListItemText primary={file?.filename} />
        </ListItem>
      ))}
    </List>
  );
}
