/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { Grid } from '@mui/material';

import { TargetSegmentEdit } from './TargetSegmentEdit';
import { moveObject } from '../utils/arrayUtils';
import { handleJsonChange } from './handleJsonChange';

export const SegmentEdgeEditor = props => {
  const isMultiplechoice = props.acceptableInputs.includes('multiple_choice');
  const isOpenEnded = props.acceptableInputs.includes('open_ended');
  const isEquation = props.acceptableInputs.includes('equation');
  const isEquationMultipleChoice = props.acceptableInputs.includes('equation_multiple_choice');

  const handleTargetSegmentsChange = e => {
    const newEdgeRows = props.edgeRows.map(edge => {
      if (edge.id.toString() === e.target.name) {
        return {
          id: edge.id,
          target_segment_name: e.target.value,
          answer: edge.answer,
          training_phrases: edge.training_phrases,
          position: edge.position,
        };
      }
      return edge;
    });
    props.setEdgeRows(newEdgeRows);
  };

  const handleAnswerOrTrainingPhrasesChange = e => {
    const updatedEdgeRows = props.edgeRows.map(edge => {
      if (edge.id.toString() === e.target.name) {
        let { answer } = edge;
        let trainingPhrases = edge.training_phrases;
        if (isMultiplechoice || isOpenEnded || isEquation || isEquationMultipleChoice) {
          answer = e.target.value;
        } else {
          trainingPhrases = e.target.value.split('\n');
        }
        return {
          id: edge.id,
          target_segment_name: edge.target_segment_name,
          answer,
          training_phrases: trainingPhrases,
          position: edge.position,
        };
      }
      return edge;
    });
    props.setEdgeRows(updatedEdgeRows);
  };

  const handleEventChange = (id, value) => {
    const updatedEdgeRows = props.edgeRows.map(edge => {
      if (edge.id.toString() === id) {
        return {
          id: edge.id,
          target_segment_name: edge.target_segment_name,
          answer: value,
          training_phrases: value === '' ? [] : edge.training_phrases,
          position: edge.position,
        };
      }
      return edge;
    });
    props.setEdgeRows(updatedEdgeRows);
  };

  const deleteHandle = e => {
    let newEdgeRows = props.edgeRows.filter(edge => edge.id.toString() !== e.currentTarget.name);
    if (newEdgeRows.length === 0) {
      newEdgeRows = [initializeEdge()];
    }
    props.setEdgeRows(newEdgeRows);
  };

  const addHandle = () => {
    props.setEdgeRows([...props.edgeRows, initializeEdge()]);
  };

  function initializeEdge() {
    const newEdge = {
      id: shortid.generate(),
      target_segment_name: props.allSegmentNames[0],
      answer: '',
      training_phrases: [],
      position: props.edgeRows.length + 1,
    };
    if (isEquationMultipleChoice) {
      return {
        ...newEdge,
        answer_json: [],
      };
    }
    return newEdge;
  }

  const moveHandle = (edgeId, direction) => {
    const edge = props.edgeRows.filter(edgeRow => edgeRow.id === Number(edgeId))[0];
    let newEdges = props.edgeRows;
    newEdges = moveObject(edge, newEdges, direction);
    newEdges.forEach((ed, index) => {
      // eslint-disable-next-line no-param-reassign
      ed.position = index + 1;
    });
    props.setEdgeRows(newEdges);
  };

  return (
    <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='center'>
      {props.edgeRows.map((edge, i) => (
        <TargetSegmentEdit
          key={edge.id.toString()}
          allSegmentNames={props.allSegmentNames}
          edgeRow={edge}
          handleTargetSegmentsChange={handleTargetSegmentsChange}
          handleAnswerOrTrainingPhrasesChange={handleAnswerOrTrainingPhrasesChange}
          handleAnswerJsonChange={handleJsonChange(edge, 'answer_json')}
          handleEventChange={handleEventChange}
          lastSegment={i + 1 === props.edgeRows.length}
          firstSegment={i === 0}
          deleteHandle={deleteHandle}
          addHandle={addHandle}
          moveHandle={moveHandle}
          acceptableInputs={props.acceptableInputs}
        />
      ))}
    </Grid>
  );
};

/* eslint-disable react/forbid-prop-types */
SegmentEdgeEditor.propTypes = {
  allSegmentNames: PropTypes.array,
  edgeRows: PropTypes.array,
  setEdgeRows: PropTypes.func,
  acceptableInputs: PropTypes.array,
};
