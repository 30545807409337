import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useUpdateSegment } from 'controllers/react-query';
import { InfoOutlined } from '@mui/icons-material';
import { ACCEPT_IMAGE_TYPES, ACCEPT_VIDEO_TYPES, FileUploader, UploadButton } from 'components/Uploaders/FileUploader';
import { LessonSegment, SegmentMedia } from 'controllers/types';
import { FilePreview } from 'components/Uploaders/FilePreview';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import { VideoRecorderButton } from 'components/Uploaders/VideoRecorderButton';
import { useFeatures } from 'components/FeaturesContext';
import { useEditorContext } from 'components/Studio/Editor/EditorContext';
import { KyronEvents } from 'components/utils/KyronEvents';

const GENERATED_MEDIA_TYPES = [SegmentMedia.KYRON_VIDEO, SegmentMedia.USER_IMAGE_WITH_AUDIO];

type Props = {
  // Pass segment as a prop because sometimes this is not the activeSegment, but a MC option
  segment: LessonSegment;
  isLoading?: boolean;
  columnLayout?: boolean;
  selectedMediaType: SegmentMedia;
  setSelectedMediaType: React.Dispatch<React.SetStateAction<SegmentMedia>>;
};

export function SegmentMediaSelection({
  segment,
  isLoading,
  columnLayout,
  selectedMediaType,
  setSelectedMediaType,
}: Props) {
  const { show_video_recorder: showVideoRecorder } = useFeatures();
  const { editorState } = useEditorContext();
  const { activeSectionId } = editorState;
  const { mutateAsync: updateSegment } = useUpdateSegment({
    segmentId: segment?.id,
    sectionId: activeSectionId,
  });
  const { image_url: imageUrl, video_url: videoUrl, media_type: currentMediaType } = segment;

  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [mediaTypeInProgress, setMediaTypeInProgress] = useState<SegmentMedia | null>(null);
  const [userVideoFile, setUserVideoFile] = useState<ActiveStorageBlob | null>(
    videoUrl && currentMediaType === SegmentMedia.USER_VIDEO
      ? { url: videoUrl, media_type: SegmentMedia.USER_VIDEO }
      : null,
  );
  const [imageFile, setImageFile] = useState<ActiveStorageBlob | null>(
    imageUrl ? { url: imageUrl, media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO } : null,
  );

  if (!segment) {
    console.error('Segment not provided to SegmentMediaSelection');
    return null;
  }

  function handleSelect(_: ChangeEvent<HTMLInputElement>, value: string) {
    setSelectedMediaType(value as SegmentMedia);
  }

  async function attachVideoToSegment(blobs: ActiveStorageBlob[]) {
    const blob = blobs[0];
    if (!blob.signed_id) return;

    KyronEvents.sendEvent(KyronEvents.names.UPLOAD_SEGMENT_VIDEO, { segment_id: segment.id });
    setUserVideoFile(blob);
    setMediaTypeInProgress(SegmentMedia.USER_VIDEO);

    try {
      return await updateSegment({
        lessonId: segment.lesson_id,
        payload: { ...segment, video: blob.signed_id, media_type: SegmentMedia.USER_VIDEO },
      });
    } catch (e) {
      console.error('Failed to attach video', e);
      throw e;
    } finally {
      setMediaTypeInProgress(null);
    }
  }

  async function attachImageToSegment(blobs: ActiveStorageBlob[]) {
    const blob = blobs[0];
    if (!blob.signed_id) return;

    KyronEvents.sendEvent(KyronEvents.names.UPLOAD_SEGMENT_IMAGE, { segment_id: segment.id });
    setImageFile(blob);
    setMediaTypeInProgress(SegmentMedia.USER_IMAGE_WITH_AUDIO);

    try {
      return await updateSegment({
        lessonId: segment.lesson_id,
        payload: { ...segment, image_signed_id: blob.signed_id, media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO },
      });
    } catch (e) {
      console.error('Failed to attach image', e);
      throw e;
    } finally {
      setMediaTypeInProgress(null);
    }
  }

  return (
    <Box>
      {/* TITLE */}
      <Typography id='media-selection-radio-buttons-label' variant='titleSmall'>
        Video
        <Tooltip
          placement='top'
          title='Select the type of video you want to use for this segment. You can generate a video or upload your own.'
        >
          <IconButton>
            <InfoOutlined fontSize='small' />
          </IconButton>
        </Tooltip>
      </Typography>

      {/* SELECTIONS */}
      <FormControl disabled={isLoading}>
        <RadioGroup
          aria-labelledby='media-selection-radio-buttons-label'
          name='media-selection-radio-buttons'
          value={selectedMediaType}
          onChange={handleSelect}
          sx={{ gap: 1 }}
        >
          {/* GENERATED VIDEO */}
          <FormControlLabel
            value={GENERATED_MEDIA_TYPES.includes(selectedMediaType) ? selectedMediaType : SegmentMedia.KYRON_VIDEO}
            componentsProps={{ typography: { variant: 'bodyMedium' } }}
            control={<Radio size='small' inputProps={{ 'aria-label': 'generated video' }} />}
            label='Generate Video'
            sx={{ color: 'text.secondary' }}
          />

          {/* GENERATED VIDEO OPTIONS */}
          {GENERATED_MEDIA_TYPES.includes(selectedMediaType) && (
            <FormControl disabled={isLoading} sx={{ pl: 3.5 }}>
              <Typography variant='labelLarge'>Generated video layout</Typography>
              <RadioGroup
                aria-labelledby='generated-video-layout-radio-buttons-label'
                name='generated-video-layout-radio-buttons'
                value={selectedMediaType}
                onChange={handleSelect}
              >
                {/* KYRON VIDEO */}
                <Stack direction={columnLayout ? 'column' : 'row'}>
                  <FormControlLabel
                    value={SegmentMedia.KYRON_VIDEO}
                    componentsProps={{ typography: { variant: 'bodyMedium' } }}
                    control={<Radio size='small' inputProps={{ 'aria-label': 'kyron video' }} />}
                    label='Use text bullets'
                    sx={{ color: 'text.secondary' }}
                  />
                </Stack>

                {/* USER IMAGE OPTIONS */}
                <Stack direction={columnLayout ? 'column' : 'row'}>
                  <FormControlLabel
                    value={SegmentMedia.USER_IMAGE_WITH_AUDIO}
                    componentsProps={{ typography: { variant: 'bodyMedium' } }}
                    control={<Radio size='small' inputProps={{ 'aria-label': 'user image' }} />}
                    label='Use an image'
                    sx={{ color: 'text.secondary' }}
                  />
                  {selectedMediaType === SegmentMedia.USER_IMAGE_WITH_AUDIO && (
                    <FileUploader
                      disabled={isLoading}
                      fileTypeName='image'
                      afterUpload={attachImageToSegment}
                      isAttachmentBeingProcessed={mediaTypeInProgress === SegmentMedia.USER_IMAGE_WITH_AUDIO}
                      setFormDisabled={() => setIsFormDisabled(true)}
                      acceptTypes={ACCEPT_IMAGE_TYPES}
                      existingFile={imageFile}
                    >
                      <FilePreview />
                      <UploadButton
                        buttonLabel={imageFile ? 'Replace image' : 'Choose image'}
                        shouldBeDisabled={isFormDisabled || isLoading}
                      />
                    </FileUploader>
                  )}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}

          {/* USER VIDEO */}
          <FormControlLabel
            value={SegmentMedia.USER_VIDEO}
            componentsProps={{ typography: { variant: 'bodyMedium' } }}
            control={<Radio size='small' inputProps={{ 'aria-label': 'user video' }} />}
            label='Use my own video'
            sx={{ color: 'text.secondary' }}
          />

          {/* USER VIDEO OPTIONS */}
          {selectedMediaType === SegmentMedia.USER_VIDEO && (
            <Stack direction={columnLayout ? 'column' : 'row'}>
              <FileUploader
                disabled={isLoading}
                fileTypeName='video'
                afterUpload={attachVideoToSegment}
                isAttachmentBeingProcessed={mediaTypeInProgress === SegmentMedia.USER_VIDEO}
                setFormDisabled={() => setIsFormDisabled(true)}
                acceptTypes={ACCEPT_VIDEO_TYPES}
                existingFile={userVideoFile}
              >
                <UploadButton
                  buttonLabel={userVideoFile ? 'Replace video' : 'Upload video'}
                  shouldBeDisabled={isFormDisabled || isLoading}
                />
                {showVideoRecorder ? (
                  <VideoRecorderButton buttonLabel='Record video' recordingScript={segment.description} />
                ) : null}
              </FileUploader>
            </Stack>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
