import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LessonInstance, LessonCollection } from 'controllers/types';
import { StructuredDataLessonCollections } from 'components/seo/StructuredDataLessonCollections';
import { sortByPosition } from 'components/utils/arrayUtils';
import { useLessonContainerQuery } from 'controllers/react-query';
import { usePausedLessonInstancesQuery } from 'controllers/react-query/lessonInstanceHooks';
import { CatalogLessonCard } from '../CatalogLessonCard/CatalogLessonCard';
import { CatalogLessonSelectionDialog } from '../CatalogLessonSelectionDialog/CatalogLessonSelectionDialog';
import { LoadingIndicator } from '../LoadingIndicator';
import { Row } from '../Row/Row';

export function CatalogUnit() {
  const { unitId } = useParams();
  const { data: unit, isFetching: isUnitLoading } = useLessonContainerQuery(Number(unitId));
  const { data: lessonInstances, isFetching: isPausedLessonInstancesLoading } = usePausedLessonInstancesQuery();
  const [open, setOpen] = React.useState(false);
  const [selectedLesson, setSelectedLesson] = React.useState<LessonCollection | null>(null);
  const lessonCollections = unit?.lesson_collections || [];
  sortByPosition(lessonCollections);

  function openLessonSelectionDialog(lesson: LessonCollection) {
    setOpen(true);
    setSelectedLesson(lesson);
  }

  function closeLessonSelectionDialog() {
    setOpen(false);
    setSelectedLesson(null);
  }

  function pausedLessonInstanceExists(lessonCollection: LessonCollection) {
    // This logic could be in the back-end but would then have to be passed
    // to the front-end as a more complex data structure (lesson_instances by
    // lesson_collection vs. just lesson_instances. It is unclear if that
    // makes sense to implement at this point in time (EK-11/21/23)
    let filteredLessonInstance: LessonInstance | null = null;
    lessonCollection.lessons?.forEach(lesson => {
      const candidateLessonInstance: LessonInstance | null =
        lessonInstances?.find(inst => inst.lesson_id === lesson.id) || null;
      if (candidateLessonInstance !== null) {
        if (filteredLessonInstance === null) filteredLessonInstance = candidateLessonInstance;
        if (Date.parse(filteredLessonInstance.updated_at) < Date.parse(candidateLessonInstance.updated_at))
          filteredLessonInstance = candidateLessonInstance;
      }
    });
    return {
      lessonInstanceExists: filteredLessonInstance !== null,
      lessonId: filteredLessonInstance !== null ? (filteredLessonInstance as LessonInstance).lesson_id : 0,
    };
  }

  function pausedLessonId(lessonCollection: LessonCollection) {
    const { lessonInstanceExists, lessonId } = pausedLessonInstanceExists(lessonCollection);
    if (lessonInstanceExists) {
      return lessonId;
    }
    return 0;
  }

  return (
    <>
      <StructuredDataLessonCollections lessonCollections={unit?.lesson_collections} />
      <Stack gap={8} maxWidth={800} data-testid='unit'>
        <Stack gap={2} maxWidth={560}>
          <Typography variant='displayMedium' component='h1'>
            {unit?.name}
          </Typography>
          <Typography variant='bodyLarge'>{unit?.description}</Typography>
        </Stack>
        <Stack gap={2} data-testid='unit-lessons'>
          {(isUnitLoading || isPausedLessonInstancesLoading) && (
            <Row justifyContent='center' width='100%'>
              <LoadingIndicator message='Loading...' />
            </Row>
          )}
          {/* TODO (Harish) Remove after moving to lesson collections, for a brief period the positioning wouldn't
              preserve order as its split between containers and collections
           */}
          {lessonCollections.map(lesson => (
            <CatalogLessonCard
              openLessonSelectionDialog={openLessonSelectionDialog}
              lessonCollection={lesson}
              pausedLessonId={pausedLessonId(lesson)}
              key={lesson.id}
            />
          ))}
        </Stack>
      </Stack>

      <CatalogLessonSelectionDialog
        lessonsInCollection={selectedLesson?.lessons}
        open={open}
        onClose={closeLessonSelectionDialog}
      />
    </>
  );
}
