/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardContent, CardHeader, Card, IconButton, MenuItem, Box } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { CollectionForm } from './CollectionForm';
import { LessonEdit } from './LessonEdit';
import KyronIcon from '../../assets/kyronicon-small.svg';
import { AddLessons } from './AddLessons';

const styles = {
  collectionCard: {
    margin: '10px 0px',
    shadow: '1px 1px 1px 1px #ccc',
    bgcolor: 'surfaceContainer.main',
  },
  thumbnailImage: {
    marginRight: '10px',
    maxHeight: '90px',
    maxWidth: '20vw' || '220px',
    borderRadius: '5px',
  },
  header: {
    '.MuiCardHeader-action': {
      margin: 2,
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },
};

export const ShowCollection = props => {
  const [editCollection, setEditCollection] = useState(false);

  const handleEditCollection = () => {
    setEditCollection(true);
  };

  const handleEditCollectionCancel = () => {
    setEditCollection(false);
  };

  const unattachedLessonsList = () => {
    const unattachedLessons = [];
    props.lessons?.forEach(lesson => {
      if (!lesson.lesson_collection?.id && lesson.active) {
        unattachedLessons.push(
          <MenuItem key={lesson.id} value={lesson}>
            {`${lesson.name} (${lesson.tutor?.display_name})`}
          </MenuItem>,
        );
      }
    });
    // If there are no unattached lessons, add a disabled empty option
    if (unattachedLessons.length === 0) {
      unattachedLessons.push(
        <MenuItem key={0} value={0} disabled>
          Empty
        </MenuItem>,
      );
    }
    return unattachedLessons;
  };

  const cardContent = editCollection ? (
    <CollectionForm
      collection={props.collection}
      handleEditCollection={handleEditCollection}
      handleEditCollectionCancel={handleEditCollectionCancel}
      handleFormSubmit={props.handleCollectionUpdate}
      unit={props.unit}
      units={props.units}
    />
  ) : (
    <>
      <CardHeader
        sx={styles.header}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            {props.collection.collection_thumbnail && (
              <img
                src={props.collection.collection_thumbnail || KyronIcon}
                alt='Thumbnail'
                style={styles.thumbnailImage}
              />
            )}
            <h3 className='title-large'>{props.collection.name}</h3>{' '}
          </Box>
        }
        action={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AddLessons
              handleLessonAdd={props.handleLessonAdd}
              unit={props.unit}
              collection={props.collection}
              unattachedLessonsList={unattachedLessonsList}
            />
            <IconButton
              aria-label='move collection up'
              data-testid='move-collection-up'
              onClick={() => props.reorderCollection(props.collection, props.unit.id, 'up')}
            >
              <ArrowUpward />
            </IconButton>
            <IconButton
              aria-label='move collection down'
              data-testid='move-collection-down'
              onClick={() => props.reorderCollection(props.collection, props.unit.id, 'down')}
            >
              <ArrowDownward />
            </IconButton>
            <IconButton aria-label='edit collection' data-testid='edit-collection' onClick={handleEditCollection}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label='delete collection' data-testid='delete-collection' onClick={props.openDeleteModal}>
              <DeleteIcon />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        {props.collection?.lessons?.map(lesson => (
          <div key={lesson.id} data-testid='lessons-list'>
            <LessonEdit
              lesson={lesson}
              units={props.units}
              handleLessonUpdate={props.handleLessonUpdate}
              handleLessonRemove={props.handleLessonRemove}
              unit={props.unit}
              reorderLesson={props.reorderLesson}
            />
          </div>
        ))}
      </CardContent>
    </>
  );

  return <Card sx={styles.collectionCard}>{cardContent}</Card>;
};

/* eslint-disable react/forbid-prop-types */

ShowCollection.propTypes = {
  collection: PropTypes.object,
  lessons: PropTypes.array,
  units: PropTypes.array,
  handleLessonAdd: PropTypes.func,
  handleLessonUpdate: PropTypes.func,
  handleLessonRemove: PropTypes.func,
  unit: PropTypes.object,
  openDeleteModal: PropTypes.func,
  handleCollectionUpdate: PropTypes.func,
  reorderCollection: PropTypes.func,
  reorderLesson: PropTypes.func,
};
