import { CanvasCourseListing, CanvasCourse, Lesson, DetailedSegmentType } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutationV2 } from './kyronMutation';

export const useCanvasCourses = () =>
  useKyronQuery<{ courses?: CanvasCourseListing[]; error?: string }>(`/canvas_courses`);

export const useCanvasCourse = (courseId: string) => useKyronQuery<CanvasCourse>(`/canvas_courses/${courseId}`);

export const useLinkCanvasCourse = () =>
  useKyronMutationV2<{ payload: { courseId: string; moduleId: string; emdStyle: DetailedSegmentType } }, Lesson>(
    `/canvas_courses/link`,
    {
      method: 'POST',
    },
  );
