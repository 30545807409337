import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const FeaturesContext = React.createContext<Features>({});

export type Features = Record<string, boolean>;

type FeatureProviderProps = {
  children: React.ReactNode;
  features: Features;
};
export const FeaturesProvider = ({ children, features }: FeatureProviderProps) => (
  <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>
);

export const useFeatures = <T extends Features>(): T => {
  const features = useContext(FeaturesContext);
  return features as T;
};

export function useRedirectIfFeatureDisabled({ feature, redirectTo }: { feature: string; redirectTo: string }) {
  const navigate = useNavigate();
  const features = useFeatures();
  const featureEnabled = features[feature];
  if (redirectTo && navigate && !featureEnabled) {
    navigate(redirectTo);
  }
}
