import { Alert, Box, Typography, TypographyProps, capitalize, styled } from '@mui/material';
import { KyronTooltip } from 'components/KyronTooltip';
import { useModal } from 'components/utils/ModalContext';
import React, { useCallback, useContext } from 'react';
import ReactPlayer from 'react-player';
import FileIcon from '@mui/icons-material/UploadFile';
import { SegmentMedia } from 'controllers/types';
import { FileUploaderContext, FileUploaderProps, UploaderElementProps } from './FileUploader';
import { ActiveStorageBlob } from './types';

interface StyleProps extends TypographyProps {
  disabled: boolean;
}

const FileListItem = styled(Typography, { shouldForwardProp: p => p !== 'disabled' })<StyleProps>(
  ({ theme, disabled }) => ({
    transition: 'color 50ms linear',
    lineHeight: '2.2',
    ...(!disabled && {
      cursor: 'pointer',
      '&:hover:active': { color: theme.palette.primary.light },
      '&:hover': { color: theme.palette.primary.dark },
    }),
    ...(disabled && {
      cursor: 'default',
      color: theme.palette.text.disabled,
    }),
  }),
);

export function FilePreview({ shouldBeDisabled = false, fileTypeName }: UploaderElementProps) {
  const { openModal } = useModal();
  const uploaderCtx = useContext(FileUploaderContext);
  const existingFile = uploaderCtx?.files?.[0]; // Only one file is supported in this component

  const openFilePreview = useCallback(() => {
    if (shouldBeDisabled) return;
    openModal({
      id: `user-${fileTypeName}-preview`,
      title: `Uploaded ${fileTypeName}`,
      content: (
        <Box display='flex' justifyContent='center'>
          {getFilePreview(existingFile)}
        </Box>
      ),
      fullWidth: true,
    });
    // Disabling this rule because I MUST pass existingFile.url as a dependency and it tells me I shouldn't
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFile, existingFile?.url, openModal, fileTypeName, shouldBeDisabled]);

  return uploaderCtx?.isThereAnExistingFile ? (
    <KyronTooltip inactive={shouldBeDisabled} title='Click to preview file' placement='top'>
      <FileListItem
        variant='titleSmall'
        noWrap
        color='primary'
        onClick={openFilePreview}
        disabled={shouldBeDisabled}
        data-testid='file-uploader-file-name'
      >
        <FileIcon sx={{ fontSize: '1.5rem', transform: 'translateY(0.25em)' }} />
        {existingFile?.filename || `Uploaded ${fileTypeName}`}
      </FileListItem>
    </KyronTooltip>
  ) : null;
}

export function getFilePreview(
  existingFile?: ActiveStorageBlob | null,
  fileTypeName: FileUploaderProps['fileTypeName'] = 'file',
) {
  const fileTypeNameCaps = capitalize(fileTypeName);
  const renderNote = (msg: string, isError?: boolean) => <Alert severity={isError ? 'error' : 'info'}>{msg}</Alert>;

  if (!existingFile) return renderNote(`${fileTypeNameCaps} cannot be found.`);
  if (!existingFile.url) return renderNote(`Cannot render ${fileTypeName} preview. Because the URL is missing.`);

  const isProcessing = existingFile.url.match(/status=processing/i);
  if (isProcessing)
    return renderNote(`${fileTypeNameCaps} is still being processed. Refresh your page to get the latest status.`);

  const isError = existingFile.url.match(/status=error/i);
  if (isError) return renderNote('There was an error when processing the file. Please try again.', true);

  if (existingFile.media_type === SegmentMedia.USER_VIDEO) {
    return (
      <div style={{ overflow: 'hidden', borderRadius: 16 }}>
        <ReactPlayer height='100%' width='100%' url={existingFile?.url} controls />
      </div>
    );
  }

  if (existingFile.media_type === SegmentMedia.USER_IMAGE_WITH_AUDIO) {
    return (
      <img src={existingFile.url} alt='uploaded' style={{ maxHeight: '100%', maxWidth: '100%', borderRadius: 16 }} />
    );
  }
}

FilePreview.displayName = 'FilePreview';
