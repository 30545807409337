import { createConsumer } from '@rails/actioncable';
import { useEffect, useState } from 'react';
import { BackgroundTask } from '../controllers/types';
import { useCurrentUser } from '../controllers/react-query/userHooks';
import { devConsole } from '../components/utils/logUtils';

// BEWARE: This type should match the model sent from Lesson#broadcast_background_tasks
type ProgressData = {
  activeTasks: BackgroundTask[];
  failedTasks: BackgroundTask[];
  isAnyProcessing: boolean;
};

/**
 * User notifications listener hook.
 */
export function useUserNotifications() {
  const { data: user } = useCurrentUser();
  const [notifications, setNotifications] = useState<Record<string, unknown>>({});

  useEffect(subscribeToChannel, [user]);
  function subscribeToChannel() {
    if (!user) devConsole.warn('Cannot subscribe to BTProgressChannel: User is not defined');

    if (user) {
      const subscription = subscribeToUserNotificationChannel(user.id, payload => {
        setNotifications(prevNotif => ({ ...prevNotif, ...payload }));
      });

      return () => {
        subscription.unsubscribe();
        // consumer.disconnect(); // this removes all subscriptions, not sure yet if we should have it here
      };
    }
  }

  return notifications;
}

/** ***************************************************************************
 * ActionCable subscription helper
 **************************************************************************** */
type SubscriptionFnOnReceived = (d: ProgressData) => void;

function subscribeToUserNotificationChannel(userId: number | string, onReceived: SubscriptionFnOnReceived) {
  const consumer = createConsumer('/cable');

  return consumer.subscriptions.create(
    { channel: 'UserNotificationChannel', userId },
    {
      // Note (finding): received callback has to be initialized here (even if it doesn't do anything) in order to be
      // able to call subscription.received to add more received callbacks after subscription.
      received: data => {
        if (data && typeof data !== 'function') {
          if (data.cable_error) {
            devConsole.error('Error in UserNotificationChannel:', JSON.stringify(data, null, 2));
          }
          // TODO(EGE): This is a weird behavior from ActiveCable subscription, it passes the received function as the
          //  argument on first render. Not sure why. So I am guarding against setting the received callback in tha state.
          onReceived?.(data);
        }
      },
    },
  );
}
