export function sortById(arr: { id: number }[]) {
  arr.sort((a, b) => a.id - b.id);
}

interface KeyProperty {
  key: number;
}

export function sortByKey<T extends KeyProperty>(arr: T[]) {
  return arr.sort((a, b) => a.key - b.key);
}

export function sortByPosition(arr: { id: number; position: number }[]) {
  arr.sort((a, b) => a.position - b.position || a.id - b.id);
}

export function moveObject(object: { id: number }, allObjects: (typeof object)[], direction: string) {
  /* eslint-disable no-param-reassign */
  const objectIds = allObjects.map(obj => obj.id);
  const index = objectIds.indexOf(object.id);

  if (direction === 'up') {
    if (index > 0) {
      allObjects[index] = allObjects[index - 1];
      allObjects[index - 1] = object;
    }
  } else if (direction === 'down') {
    if (index < allObjects.length - 1) {
      allObjects[index] = allObjects[index + 1];
      allObjects[index + 1] = object;
    }
  }
  return allObjects;
}
