/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';
import { Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SearchField } from 'components/SearchField';
import { Segment } from 'components/Segment';
import { Edge, LessonSegment, Misconception } from 'controllers/types';
import { useLessonSegmentsQuery } from 'controllers/react-query';
import { KyronClient } from '../utils/KyronClient';

const styles = {
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
};

type Props = {
  lessonId: number;
  allMisconceptions: Misconception[];
};

export const LessonSegmentsShow = (props: Props) => {
  // tools
  const client = new KyronClient();
  const { enqueueSnackbar } = useSnackbar();

  // state
  const [page, setPage] = useState(1);
  const [lessonId] = useState(props.lessonId);
  const [searchString, setSearchString] = useState('');
  const {
    refetch: refetchLessonSegments, // used for manual refetching in old (non-react-query) API calls
    data: lessonSegmentsData,
    isFetching: isFetchingLessonSegments,
    error: lessonSegmentsError,
  } = useLessonSegmentsQuery({
    lessonId,
    page,
    perPage: 10,
    search: searchString,
  });
  const lessonSegments = lessonSegmentsData?.lesson_segments || [];
  const numberOfPages = lessonSegmentsData?.meta.total_pages || 1;

  // isLoading state will hold segment id to boolean value to indicate if a segment is being updated
  // Setter function is only meant to be used by setSegmentInProgress and not safe to use directly
  const [segmentProcessStatus, NOT_SAFE_setSegmentProcessStatus] = useState<Record<string, boolean>>({}); // eslint-disable-line camelcase, @typescript-eslint/naming-convention
  const setSegmentInProgress = (segmentLessonId: number, segmentId: number, inProgress: boolean) => {
    NOT_SAFE_setSegmentProcessStatus(prevState => {
      const newState = { ...prevState };
      newState[segmentId] = inProgress;
      return newState;
    });
  };

  // refs
  const allSegmentNames = useRef<string[]>([]);

  const handleLessonSegmentUpdate = (lessonSegment: LessonSegment, edges: Edge[]) => {
    const cleanedEdges = edges.map(edge => ({
      ...edge,
      training_phrases: edge.training_phrases.filter(tp => tp.trim() !== ''),
    }));
    const payload = { ...lessonSegment, edges: cleanedEdges };
    const formData = client.convertJsonToFormData(payload);

    // set is loading until the update finishes
    setSegmentInProgress(lessonId, lessonSegment.id, true);

    client
      .submitJsonDataWithError(`/api/v1/lesson_segments/${lessonSegment.id}`, 'PUT', formData)
      .then(resp => {
        const updatedLessonSegment = resp as LessonSegment;
        refetchLessonSegments();
        enqueueSnackbar(`Lesson Segment Updated: ${updatedLessonSegment.name}`, {
          variant: 'success',
          key: lessonSegment.id, // assign a key to the notification so it is not duplicated
        });
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setSegmentInProgress(lessonId, lessonSegment.id, false);
      });
  };

  const handleLessonSegmentDelete = (id: number) => {
    client
      .deleteDataWithError(`/api/v1/lesson_segments/${id}`)
      .then(() => {
        refetchLessonSegments();
        enqueueSnackbar('Lesson Segment Deleted');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleSearch = (value: string): void => {
    setSearchString(value);
    setPage(1);
  };

  useEffect(() => {
    client
      .getDataWithError(`/api/v1/lessons/${lessonId}/lesson_details.json`)
      .then(resp => {
        const data = resp as { lesson_segment_names: string[] };
        allSegmentNames.current = data.lesson_segment_names;
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchField
        autoFocus
        placeholder='Search by segment name'
        searchTerm={searchString}
        onSearch={handleSearch}
        isLoading={isFetchingLessonSegments}
        searchError={lessonSegmentsError && "There was an error fetching the lesson's segments"}
        sx={{ width: 400 }}
      />
      {lessonSegments.map(segment => (
        <Segment
          isLoading={segmentProcessStatus[segment.id]}
          key={segment.id}
          segment={segment}
          lessonId={lessonId}
          allMisconceptions={props.allMisconceptions}
          allSegmentNames={allSegmentNames.current}
          handleLessonSegmentDelete={handleLessonSegmentDelete}
          handleLessonSegmentUpdate={handleLessonSegmentUpdate}
        />
      ))}
      <Pagination
        style={styles.paginationWrapper}
        count={numberOfPages}
        siblingCount={5}
        shape='rounded'
        page={page}
        onChange={(_e, pg) => setPage(pg)}
      />
    </>
  );
};
