import { MutationFunction, MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { KyronClient } from 'components/utils/KyronClient';
import { API_V1 } from './constants';
import { replaceURLPathParams, URLParamValue } from '../../components/utils/urlUtils';

const defaultOptions = {
  method: 'POST',
};

interface KyronMutationOptions<TResp = unknown, TError = Error, TPayload = Record<string, unknown>, TContext = unknown>
  extends Partial<MutationOptions<TResp, TError, TPayload, TContext>> {
  method?: Request['method'];
  invalidates?: ReadonlyArray<unknown>; // Single path invalidation
  invalidatesMultiple?: ReadonlyArray<unknown>[]; // Multiple paths invalidation
  customMutationFn?: MutationFunction<TResp, TPayload>; // Custom mutation function (e.g. if you need to define endpoint based on payload)
}

/**
 * @deprecated use useKyronMutationV2 instead
 */
export const useKyronMutation = <TPayload extends Record<string, unknown>, TResp = unknown, TError = Error>(
  endpoint: string,
  {
    invalidates,
    invalidatesMultiple,
    customMutationFn,
    ...options
  }: KyronMutationOptions<TResp, TError, TPayload> = {},
) => {
  if (endpoint.includes(API_V1)) throw new Error(`endpoint should not include API prefix (${API_V1})`);

  const client = new KyronClient();
  const queryClient = useQueryClient();

  const url = API_V1 + endpoint;
  const opts = { ...defaultOptions, ...options };
  const mutationFn =
    customMutationFn ||
    (async (payload: TPayload) => {
      const form = client.convertJsonToFormData(payload, { convertToSnakeCase: true });
      return client.submitJsonDataWithError<TResp>(url, opts.method, form);
    });

  const wrappedConfig = {
    ...opts, // This is to ensure that if you have other react-query options in the future, they are spread into the config
    onSuccess: (data: TResp, variables: TPayload, context: unknown) => {
      // Following code is to define some endpoints to invalidate as a successful mutation side effect
      // when there is an invalidates array in options, this logic will invalidate the endpoint data in cache
      const queryKeyToInvalidate = invalidates;
      // Invalidate single query key
      if (queryKeyToInvalidate) {
        return queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate, exact: false });
      }
      const multipleQueryKeysToInvalidate = invalidatesMultiple;
      // Invalidate multiple query keys
      if (multipleQueryKeysToInvalidate) {
        multipleQueryKeysToInvalidate.map(queryKey => queryClient.invalidateQueries({ queryKey, exact: false }));
      }

      // Call any onSuccess callbacks passed in from the caller of "mutate" function in the component
      opts.onSuccess?.(data, variables, context);
    },
  };

  return useMutation<TResp, TError, TPayload>({ mutationFn, ...wrappedConfig });
};

type RequestPayload = Record<string, unknown>;
export type PayloadAndRequestParams = Record<string, URLParamValue | RequestPayload> & { payload?: RequestPayload };
interface KyronMutationV2Options<
  TResp = unknown,
  TError = Error,
  TPayloadAndRequestParams = PayloadAndRequestParams,
  TContext = unknown,
> extends Partial<MutationOptions<TResp, TError, TPayloadAndRequestParams, TContext>> {
  method?: Request['method'];
  invalidates?: ReadonlyArray<unknown> | ((params: TPayloadAndRequestParams) => ReadonlyArray<unknown>); // Single path invalidation
  invalidatesMultiple?: ReadonlyArray<unknown[]> | ((params: TPayloadAndRequestParams) => ReadonlyArray<unknown[]>); // Multiple paths invalidation
}

/**
 * This is an improved version of useKyronMutation that allows for more flexibility in defining the endpoint and
 * assigning params in the endpoint.
 *
 * Using useKyronMutationV2, developer will be able to do the following:
 *
 * ```tsx
 * const useUpdateResource = () => useKyronMutationV2<{ id: number, payload: Resource }>('/resources/:id')
 *
 * const { mutate } = useUpdateResource();
 *
 * // id is going to be placed in the matching URL param placeholder in the endpoint path
 * // resulting in a request to "/resources/1" with the payload "{ name: 'new name' }"
 * mutate({ id: 1, payload: { name: 'new name' } });
 * ```
 *
 * Another example can be:
 *
 * ```tsx
 * const useUpdateResource = () => useKyronMutationV2<{ page: number, perPage: number }>('/resources?page=:page&per_page=:per_page', {
 *  invalidates: ({ page, perPage }) => [`/resources?page=${page}&per_page=${perPage}`]
 * })
 * ```
 *
 * @param endpoint
 * @param kyronMutationV2Options
 */
export const useKyronMutationV2 = <
  TPayloadAndRequestParams extends PayloadAndRequestParams,
  TResp = unknown,
  TError = Error,
>(
  endpoint: string,
  kyronMutationV2Options?: KyronMutationV2Options<TResp, TError, TPayloadAndRequestParams>,
) => {
  const { invalidates, invalidatesMultiple, ...options } = kyronMutationV2Options || {};
  if (endpoint.includes(API_V1)) throw new Error(`endpoint should not include API prefix (${API_V1})`);
  if (endpoint.includes(':payload'))
    throw new Error('endpoint parameter must not include ":payload" as it is a reserved keyword');

  const client = new KyronClient();
  const queryClient = useQueryClient();

  const opts = { ...defaultOptions, ...options };
  const mutationFn = async (urlParamsAndPayload?: TPayloadAndRequestParams) => {
    if (endpoint.includes(':') && !urlParamsAndPayload)
      throw new Error(`endpoint requires params: ${endpoint} but none provided to mutation function`);
    const { payload, ...rest } = urlParamsAndPayload || {};
    const urlParamsOnly = rest as Record<string, URLParamValue>;
    const payloadOnly = payload as TPayloadAndRequestParams;

    const url = API_V1 + replaceURLPathParams(endpoint, urlParamsOnly);
    const form = client.convertJsonToFormData(payloadOnly, { convertToSnakeCase: true });
    return client
      .submitJsonDataWithError<TResp>(url, opts.method, form)
      .then((resp: TResp) => invalidateQueries(resp, urlParamsAndPayload));
  };

  // invalidateQueries is to invalidate cached data on a successful mutation
  // when there is an invalidates/invalidatesMultiple array in options, this logic will invalidate the endpoint data in cache
  async function invalidateQueries(resp: TResp, params = {} as TPayloadAndRequestParams): Promise<TResp> {
    if (invalidates) {
      const queryKeyToInvalidate =
        typeof invalidates === 'function'
          ? invalidates(params) // If a function, call it with the params to get the query key array to invalidate
          : invalidates;

      await queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate || [], exact: false });
    }

    if (invalidatesMultiple) {
      const queryKeysToInvalidate =
        typeof invalidatesMultiple === 'function'
          ? invalidatesMultiple(params) // If a function, call it with the params to get the query key array to invalidate
          : invalidatesMultiple;

      await Promise.all(
        queryKeysToInvalidate.map(queryKey => queryClient.invalidateQueries({ queryKey, exact: false })) || [],
      );
    }

    return resp;
  }

  return useMutation<TResp, TError, TPayloadAndRequestParams>({ mutationFn, ...opts });
};
