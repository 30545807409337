import React from 'react';
import { Chip, ChipProps, Stack } from '@mui/material';
import { Lesson } from 'controllers/types';
import { useFeatures } from 'components/FeaturesContext';
import { getLessonCollectionURL } from 'components/utils/urlUtils';
import { Link as RouterLink } from 'react-router-dom';

enum LABELS {
  published = 'Public',
  unpublished = 'Unpublished Changes',
  draft = 'Draft',
  private = 'Private',
  live = 'Live',
}

type StatusChipProps = {
  label: LABELS;
  bgcolor?: string;
  to?: string;
} & ChipProps;
const StatusChip = ({ label, bgcolor, ...props }: StatusChipProps) => (
  <Chip label={label} sx={{ bgcolor, boxShadow: 1, borderRadius: 1, fontWeight: 'bold' }} {...props} />
);

export function LessonStatusTags({ lesson }: { lesson?: Lesson }) {
  const { new_plg_flow: isNewPlgFlow } = useFeatures();
  if (!lesson) {
    return null;
  }

  const publishedLessonCollection = lesson.published_lesson_collection;

  const isDraft = !publishedLessonCollection;
  const isPublic = publishedLessonCollection?.visibility === 'PUBLIC';
  const isPrivate = publishedLessonCollection?.visibility === 'PRIVATE';
  const hasUnpublishedChanges = Boolean(lesson.has_unpublished_changes);

  return (
    <Stack direction='row' gap={1}>
      {isNewPlgFlow && publishedLessonCollection && (
        <StatusChip
          label={LABELS.live}
          bgcolor='successContainer.main'
          component={RouterLink}
          to={getLessonCollectionURL(publishedLessonCollection.id)}
          clickable
        />
      )}
      {!isNewPlgFlow && isDraft && <StatusChip label={LABELS.draft} bgcolor='surface.main' />}
      {!isNewPlgFlow && isPublic && <StatusChip label={LABELS.published} bgcolor='successContainer.main' />}
      {!isNewPlgFlow && isPrivate && <StatusChip label={LABELS.private} bgcolor='tertiaryContainer.main' />}
      {hasUnpublishedChanges && <StatusChip label={LABELS.unpublished} />}
    </Stack>
  );
}
