import { createContext, useContext } from 'react';
import { BackgroundTask } from '../../controllers/types';

export const AppContext = createContext({} as AppCtx);
export const useAppContext = () => useContext(AppContext);

// Just to be safer, I assign the type as Partial here. So that we use this entity defensively and be ready for
// any possible missing keys sent from the backend.
export type CategorizedTasks = Partial<{
  lessonPlanning: BackgroundTask[];
  lessonPublishing: BackgroundTask[];
  lessonTranslation: BackgroundTask[];
  videoGeneration: BackgroundTask[];
  videoRegeneration: BackgroundTask[];
}>;

export const emptyCategorizedTasks: CategorizedTasks = {
  lessonPlanning: [],
  lessonPublishing: [],
  lessonTranslation: [],
  videoGeneration: [],
  videoRegeneration: [],
};

export const initialBackgroundTasks = {
  active: emptyCategorizedTasks,
  failed: emptyCategorizedTasks,
};

export type AppState = {
  notifications: {
    backgroundTasks: {
      active: Partial<CategorizedTasks>;
      failed: Partial<CategorizedTasks>;
    };
  };
};

export type AppCtx = {
  appState: AppState;
  setAppState: (newState?: Partial<AppState> | ((prevState: AppState) => Partial<AppState>)) => void;
  notifications: AppState['notifications'];
};
