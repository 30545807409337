import { LessonInstance, StudentResponseRow, Transcript, Tutor } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';

export const useStudentResponseLogsQuery = (id: number) =>
  useKyronQuery<StudentResponseRow[]>(`/lesson_instances/${id}/student_response_logs`);

export const usePausedLessonInstancesQuery = () => useKyronQuery<LessonInstance[]>('/lesson_instances/paused');

export const useTranscriptQuery = (id: number) => useKyronQuery<Transcript>(`/lesson_instances/${id}/transcript`);

export const useLessonInstanceTutorQuery = (id: number) => useKyronQuery<Tutor>(`/lesson_instances/${id}/tutor`);
