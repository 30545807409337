// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LibraryListItem when variant=card renders the mobile component 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 1;
}

.emotion-1 {
  position: relative;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: absolute;
  top: 8px;
  left: 8px;
}

.emotion-3 {
  max-width: 100%;
  font-family: Open Sans;
  font-size: 0.8125rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
  color: #1C1B1F;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: unset;
  outline: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: #E3DFFF;
  box-shadow: 1;
  border-radius: 20px;
  font-weight: bold;
}

.emotion-3.Mui-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.emotion-3 .MuiChip-avatar {
  margin-left: 5px;
  margin-right: -6px;
  width: 24px;
  height: 24px;
  color: #616161;
  font-size: 0.75rem;
}

.emotion-3 .MuiChip-avatarColorPrimary {
  color: #FFFFFF;
  background-color: rgb(52, 37, 178);
}

.emotion-3 .MuiChip-avatarColorSecondary {
  color: #FFFFFF;
  background-color: rgb(111, 0, 135);
}

.emotion-3 .MuiChip-avatarSmall {
  margin-left: 4px;
  margin-right: -4px;
  width: 18px;
  height: 18px;
  font-size: 0.625rem;
}

.emotion-3 .MuiChip-icon {
  margin-left: 5px;
  margin-right: -6px;
  color: #616161;
}

.emotion-3 .MuiChip-deleteIcon {
  -webkit-tap-highlight-color: transparent;
  color: rgba(28, 27, 31, 0.26);
  font-size: 22px;
  cursor: pointer;
  margin: 0 5px 0 -6px;
}

.emotion-3 .MuiChip-deleteIcon:hover {
  color: rgba(28, 27, 31, 0.4);
}

.emotion-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}

.emotion-5 {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-6>:not(style):not(style) {
  margin: 0;
}

.emotion-6>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.emotion-8 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.emotion-9 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-10>:not(style):not(style) {
  margin: 0;
}

.emotion-10>:not(style)~:not(style) {
  margin-top: 8px;
}

.emotion-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-12 {
  display: inline-block;
  color: #4B35FF;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
}

.emotion-13 {
  display: block;
}

.emotion-14 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-15 {
  margin: 0;
  font: inherit;
  font-size: 1em;
}

.emotion-16 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-16::-moz-focus-inner {
  border-style: none;
}

.emotion-16.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-16 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-16:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-16:hover {
    background-color: transparent;
  }
}

.emotion-16.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-17 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-18 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    class="MuiStack-root emotion-0"
  >
    <div
      class="MuiBox-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-filledDefault emotion-3"
        >
          <span
            class="MuiChip-label MuiChip-labelMedium emotion-4"
          >
            Draft
          </span>
        </div>
      </div>
      <a
        href="/studio/courses/2"
        style="text-decoration: none;"
      >
        <div
          class="MuiBox-root emotion-5"
          h="100%"
          w="100%"
        >
          <img
            alt="lesson collection thumbnail"
            data-testid="lesson-thumbnail"
            src="https://example.com/image.jpg"
            style="object-fit: cover; height: inherit; width: 100%;"
          />
        </div>
      </a>
    </div>
    <div
      class="MuiStack-root emotion-6"
    >
      <div
        class="MuiStack-root emotion-7"
      >
        <a
          class="MuiTypography-root MuiTypography-titleSmall emotion-8"
          href="/studio/courses/2"
        >
          Published Lesson 1
        </a>
        <p
          class="MuiTypography-root MuiTypography-bodySmall emotion-9"
        >
          Updated 13 years ago
        </p>
      </div>
      <div
        class="MuiStack-root emotion-10"
      >
        <div
          class="MuiStack-root emotion-11"
          data-testid="loading-indicator"
        >
          <span
            class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary emotion-12"
            role="progressbar"
            style="width: 1.2em; height: 1.2em;"
          >
            <svg
              class="MuiCircularProgress-svg emotion-13"
              viewBox="22 22 44 44"
            >
              <circle
                class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-14"
                cx="44"
                cy="44"
                fill="none"
                r="20.2"
                stroke-width="3.6"
              />
            </svg>
          </span>
          <span
            class="MuiTypography-root MuiTypography-inherit emotion-15"
          />
        </div>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          aria-label="menu"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-16"
          data-testid="kyron-menu-button"
          tabindex="0"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-17"
            data-testid="MoreHorizIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-18"
          />
        </button>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
