// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StudioLayout should render 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: 64px;
  background-color: #FFFFFF;
}

@media (min-width:0px) {
  .emotion-0 {
    left: 0;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    left: 320px;
  }
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 64px;
  padding: 0 16px;
  background-color: #FFFFFF;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  margin-right: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}

.emotion-3 {
  margin-top: 12px;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  margin-left: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-focus-inner {
  border-style: none;
}

.emotion-5.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-5 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-5:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-5:hover {
    background-color: transparent;
  }
}

.emotion-5.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-6 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  width: 48px;
  height: 48px;
  background-color: #193615;
}

.emotion-7 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<header
    class="MuiBox-root emotion-0"
    data-testid="app-bar"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      />
      <div
        class="MuiBox-root emotion-3"
      />
      <div
        class="MuiStack-root emotion-4"
      >
        <button
          aria-haspopup="true"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-5"
          data-testid="account-menu-button"
          tabindex="0"
          type="button"
        >
          <div
            class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-6"
            data-testid="initials-avatar"
          >
            T
          </div>
          <span
            class="MuiTouchRipple-root emotion-7"
          />
        </button>
      </div>
    </div>
  </header>
  .emotion-0 {
  height: 64px;
}

<div
    class="MuiBox-root emotion-0"
    data-comment="header padding"
  />
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 32px 16px;
  background-color: #FCFAFD;
  position: fixed;
  bottom: 0;
  top: 0;
  width: 320px;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 40px;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 4px 10px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  border-radius: 4px;
  color: #1C1B1FB3;
  height: 56px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  min-width: 0;
  padding-left: 8px;
  padding-right: 8px;
  gap: 8px;
}

.emotion-1::-moz-focus-inner {
  border-style: none;
}

.emotion-1.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-1 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-1:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-1:hover {
    background-color: #4B35FF;
  }
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-1:hover {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  box-shadow: none;
}

.emotion-1 typography {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-1:hover {
  background-color: #D9D3DD;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: #F1ECF4;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.emotion-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  background-color: #565267;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.emotion-5 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.emotion-6 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1C1B1FB3;
  text-align: left;
}

.emotion-7 {
  display: inherit;
  margin-right: -2px;
  margin-left: 8px;
}

.emotion-7>*:nth-of-type(1) {
  font-size: 18px;
}

.emotion-8 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-9 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #1C1B1FB3;
  overflow-x: hidden;
  overflow-y: auto;
}

.emotion-11 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-wrap: nowrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: inherit;
}

.emotion-11::-moz-focus-inner {
  border-style: none;
}

.emotion-11.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-11 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-11:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-11:hover {
    background-color: transparent;
  }
}

.emotion-11.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-11.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-11.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-11.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-11.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-11.Mui-disabled {
  opacity: 0.38;
}

.emotion-11:focus,
.emotion-11:hover {
  background-color: #F1ECF4;
  color: #47464F;
}

.emotion-11.active {
  background-color: #F3DBF8;
  color: #340042;
}

.emotion-11.active:focus,
.emotion-11.active:hover {
  background-color: #E1CDE6;
  color: #340042;
}

.emotion-12 {
  min-width: 56px;
  color: rgba(0, 0, 0, 0.54);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: inherit;
  margin-right: 8px;
  min-width: 0px;
  margin-right: 16px;
}

.emotion-13 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-14 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.emotion-14 .MuiListItemText-secondary {
  color: inherit;
}

.emotion-15 {
  margin: 0;
  font: inherit;
  display: block;
}

.emotion-29 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
  margin-top: 16px;
  margin-bottom: 16px;
}

.emotion-36 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-wrap: nowrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: inherit;
}

.emotion-36::-moz-focus-inner {
  border-style: none;
}

.emotion-36.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-36 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-36:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-36:hover {
    background-color: transparent;
  }
}

.emotion-36.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-36.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-36.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-36.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-36.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-36.Mui-disabled {
  opacity: 0.38;
}

.emotion-36::-moz-focus-inner {
  border-style: none;
}

.emotion-36.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-36 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-36:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-36:hover {
    background-color: transparent;
  }
}

.emotion-36.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-36.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-36.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-36.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-36.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-36.Mui-disabled {
  opacity: 0.38;
}

.emotion-36:focus,
.emotion-36:hover {
  background-color: #F1ECF4;
  color: #47464F;
}

.emotion-36.active {
  background-color: #F3DBF8;
  color: #340042;
}

.emotion-36.active:focus,
.emotion-36.active:hover {
  background-color: #E1CDE6;
  color: #340042;
}

.emotion-49 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  bottom: 80px;
  left: 306px;
  z-index: 99999;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  width: 24px;
  height: 24px;
  background: #F1ECF4;
}

.emotion-49::-moz-focus-inner {
  border-style: none;
}

.emotion-49.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-49 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-49:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-49:hover {
    background-color: transparent;
  }
}

.emotion-49.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-50 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

<div
    class="MuiStack-root emotion-0"
    data-testid="studio-sidebar"
  >
    <a
      aria-label="Home"
      href="/"
    >
      <svg
        data-testid="logo"
        fill="#4B35FF"
        height="24px"
        viewBox="0 0 780.29 106.49"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <g>
              <path
                d="m128.66,11.77c0-6.5-5.27-11.77-11.77-11.77s-11.77,5.27-11.77,11.77c0,4.34,2.35,8.11,5.84,10.16v58.43h-29.09c-6.95,0-13.2,3.05-17.5,7.87-4.3-4.82-10.55-7.87-17.5-7.87h-18.64v11.88h18.64c6.34,0,11.5,5.13,11.56,11.46,0,.04,0,.07,0,.11h11.88s0-.07,0-.11c.06-6.33,5.22-11.46,11.56-11.46h40.97V21.9c3.47-2.05,5.81-5.81,5.81-10.14Z"
              />
              <path
                d="m17.79,76.24V17.57h29.09c6.34,0,11.5,5.13,11.56,11.46,0,.04,0,.07,0,.11h11.88s0-.07,0-.11c.06-6.33,5.22-11.46,11.56-11.46h17.33V5.69h-17.33c-6.95,0-13.2,3.04-17.5,7.87-4.3-4.82-10.55-7.87-17.5-7.87H5.92v70.43c-3.53,2.03-5.92,5.83-5.92,10.2,0,6.5,5.27,11.77,11.77,11.77s11.77-5.27,11.77-11.77c0-4.29-2.31-8.04-5.74-10.09Z"
              />
            </g>
            <g>
              <path
                d="m161.27,54.5h-8.35v31.21h-11.67V14.6h11.67v29.05h8.28l20.25-29.05h14.37l-24.3,34.3,26.52,36.8h-14.5l-22.26-31.21Z"
              />
              <path
                d="m247.92,30.86l-25.2,55.85c-6.2,13.79-11.65,18.67-22.83,18.67h-5.57v-10.16h4.7c7.25,0,9.49-2.12,13.5-10.89l1.23-2.58-23.88-50.88h12.26l17.43,38.25,16.36-38.25h12Z"
              />
              <path
                d="m284.01,30.55v10.65h-5.95c-9.33,0-13.98,5.32-13.98,15.25v29.25h-11.28V30.86h7.63l1.91,7.49c3.92-5.2,9.31-7.79,16.8-7.79h4.88Z"
              />
              <path
                d="m313.38,87.04c-16.78,0-29.33-12.28-29.33-28.76s12.55-28.76,29.33-28.76,29.33,12.25,29.33,28.76-12.58,28.76-29.33,28.76h0Zm17.85-28.76c0-10.5-7.47-18-17.85-18s-17.85,7.5-17.85,18,7.47,18,17.85,18,17.85-7.5,17.85-18Z"
              />
              <path
                d="m377.54,29.61c12.83,0,20.84,9.56,20.84,23.07v33.03h-11.27v-30.73c0-9.96-4.33-15.25-12.58-15.25s-14.73,6.93-14.73,16.62v29.37h-11.28V30.86h7.86l2.19,7.65c4.15-5.53,10.92-8.9,18.98-8.9h0Z"
              />
              <path
                d="m445.34,77.35v8.36h-40.15V14.6h8.91v62.75h31.24Z"
              />
              <path
                d="m499.74,61.63h-46.76c1.36,10.16,8.92,16.83,19.31,16.83,7.59,0,13.69-3.55,17.08-9.47h9.11c-4.64,11.31-14.29,17.92-26.18,17.92-15.91,0-27.97-12.29-27.97-28.64s12.06-28.62,27.97-28.62,27.64,12.83,27.64,28.85c0,1.04-.09,2.08-.19,3.12Zm-27.46-23.8c-9.96,0-17.32,6.27-19.11,15.82h38.09c-1.85-9.76-9.28-15.82-18.99-15.82h0Z"
              />
              <path
                d="m562.26,30.86v54.85h-7.15l-.91-8.31c-5.06,5.9-12.62,9.51-21.52,9.51-16.22,0-28.39-12.29-28.39-28.63s12.17-28.63,28.39-28.63c8.97,0,16.58,3.63,21.63,9.61l1.08-8.41h6.87Zm-8.82,27.43c0-11.67-8.49-20.27-20.12-20.27s-20.22,8.72-20.22,20.27,8.6,20.27,20.22,20.27,20.12-8.6,20.12-20.27Z"
              />
              <path
                d="m597.34,30.57v8.17h-5.6c-10.04,0-15.69,6.51-15.69,17.17v29.8h-8.63V30.86h6.13l1.4,8.35c3.88-5.61,9.63-8.64,17.53-8.64h4.86Z"
              />
              <path
                d="m650.04,52.85v32.86h-8.63v-30.82c0-11.13-5.23-17.34-14.76-17.34s-16.6,7.94-16.6,18.23v29.93h-8.63V30.86h6.13l1.68,8.6c4.14-6.11,11.15-9.8,19.65-9.8,13.03,0,21.17,9.79,21.17,23.2Z"
              />
              <path
                d="m656.05,11.46h9.35v9.9h-9.35v-9.9Zm.36,19.4h8.63v54.85h-8.63V30.86Z"
              />
              <path
                d="m719.83,52.85v32.86h-8.63v-30.82c0-11.13-5.23-17.34-14.76-17.34s-16.6,7.94-16.6,18.23v29.93h-8.63V30.86h6.13l1.68,8.6c4.14-6.11,11.15-9.8,19.65-9.8,13.03,0,21.17,9.79,21.17,23.2Z"
              />
              <path
                d="m780.29,30.86v47.18c0,17.68-10.21,28.45-26.89,28.45-13.45,0-22.99-7-25.99-18.64h8.63c2.44,6.86,9.36,10.75,17.35,10.75,10.97,0,18.45-7.28,18.45-19.45v-3.35c-4.92,5.08-11.93,8.16-19.98,8.16-15.23,0-26.98-11.77-26.98-27.1s11.77-27.2,26.98-27.2c8.77,0,16.35,3.73,21.3,9.73l1.37-8.53h5.76Zm-27.5,44.83c10.99,0,18.96-8,18.96-18.84s-7.98-18.93-18.96-18.93-19.09,8.11-19.09,18.93,8.1,18.84,19.09,18.84h0Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </a>
    <button
      aria-haspopup="true"
      class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation emotion-1"
      data-testid="organization-select"
      tabindex="0"
      type="button"
    >
      <div
        class="MuiBox-root emotion-2"
      >
        <div
          class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-3"
          data-testid="initials-avatar"
        >
          T
        </div>
      </div>
      <div
        class="MuiStack-root emotion-4"
      >
        <span
          class="MuiTypography-root MuiTypography-labelMedium MuiTypography-noWrap emotion-5"
        >
          Test Org
        </span>
        <p
          class="MuiTypography-root MuiTypography-bodySmall MuiTypography-noWrap emotion-6"
        >
          Demo Plan
        </p>
      </div>
      <span
        class="MuiButton-icon MuiButton-endIcon MuiButton-iconSizeSmall emotion-7"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-8"
          data-testid="UnfoldMoreIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15z"
          />
        </svg>
      </span>
      <span
        class="MuiTouchRipple-root emotion-9"
      />
    </button>
    <nav
      class="MuiStack-root emotion-10"
    >
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-11"
        href="/studio/courses"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-12"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
            data-testid="CollectionsBookmarkOutlinedIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m-3 2v5l-1-.75L15 9V4zm3 12H8V4h5v9l3-2.25L19 13V4h1z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-14"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-15"
          >
            Courses
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-9"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-11"
        href="/studio/classrooms"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-12"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
            data-testid="SchoolIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M5 13.18v4L12 21l7-3.82v-4L12 17zM12 3 1 9l11 6 9-4.91V17h2V9z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-14"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-15"
          >
            Classrooms
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-9"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-11"
        href="/marketplace"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-12"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
            data-testid="StoreIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 4H4v2h16zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6zm-9 4H6v-4h6z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-14"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-15"
          >
            Marketplace
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-9"
        />
      </a>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-29"
      />
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-11"
        href="/studio/settings"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-12"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
            data-testid="SettingsOutlinedIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-14"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-15"
          >
            Settings & members
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-9"
        />
      </a>
      <div
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-36"
        role="button"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-12"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
            data-testid="ContactSupportOutlinedIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M11 23.59v-3.6c-5.01-.26-9-4.42-9-9.49C2 5.26 6.26 1 11.5 1S21 5.26 21 10.5c0 4.95-3.44 9.93-8.57 12.4zM11.5 3C7.36 3 4 6.36 4 10.5S7.36 18 11.5 18H13v2.3c3.64-2.3 6-6.08 6-9.8C19 6.36 15.64 3 11.5 3m-1 11.5h2v2h-2zm2-1.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-14"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-15"
          >
            Contact support
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-9"
        />
        <span
          class="MuiTouchRipple-root emotion-9"
        />
      </div>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-11"
        href="/pricing"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-12"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
            data-testid="AttachMoneyIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-14"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-15"
          >
            Pricing
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-9"
        />
      </a>
    </nav>
    <button
      aria-label="Collapse Menu"
      class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-49"
      data-mui-internal-clone-element="true"
      data-testid="collapse-button"
      tabindex="0"
      type="button"
    >
      <svg
        aria-hidden="true"
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-50"
        data-testid="KeyboardArrowLeftIcon"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path
          d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6z"
        />
      </svg>
      <span
        class="MuiTouchRipple-root emotion-9"
      />
    </button>
  </div>
  .emotion-0 {
  -webkit-transition: padding-left 0.2s ease-in-out;
  transition: padding-left 0.2s ease-in-out;
}

@media (min-width:0px) {
  .emotion-0 {
    padding-bottom: 80px;
    padding-left: 0px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    padding-bottom: 0px;
    padding-left: 320px;
  }
}

.emotion-1 {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (min-width:0px) {
  .emotion-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width:600px) {
  .emotion-1 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  max-width: 1400px;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

<main
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiBox-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiStack-root emotion-3"
        />
      </div>
    </div>
  </main>
  .emotion-0 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  background-color: #FCFAFD;
  color: #1C1B1F;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.emotion-0::-moz-focus-inner {
  border-style: none;
}

.emotion-0.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-0 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-0:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-0:hover {
    background-color: transparent;
  }
}

.emotion-0.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-1 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-2 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<button
    aria-expanded="false"
    aria-haspopup="true"
    aria-label="menu"
    class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-0"
    data-testid="kyron-menu-button"
    tabindex="0"
    type="button"
  >
    <svg
      aria-hidden="true"
      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-1"
      data-testid="QuestionMarkIcon"
      focusable="false"
      viewBox="0 0 24 24"
    >
      <path
        d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15M14 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2"
      />
    </svg>
    <span
      class="MuiTouchRipple-root emotion-2"
    />
  </button>
</DocumentFragment>
`;
