import { Classroom, LessonSegment, LessonCollection } from 'controllers/types';
import { isEmpty } from 'lodash';
import { KyronClient } from './KyronClient';

const client = new KyronClient();

export function getLoginURLWithRedirect() {
  return `/login?redirect_to=${window.location.pathname}`;
}

export const getSearchParamStr = (search: URLSearchParams) => {
  const someSearchParam = search.size > 0;
  return someSearchParam ? `?${search.toString()}` : '';
};

export function getLTILink(lessonCollection: LessonCollection) {
  return client.pathToUrl(`/lti/v1/launch?lesson_collection_id=${lessonCollection.id}`);
}

export const getCanvasDeepLinkSetupURL = () =>
  client.pathToUrl(`/lti/v1/config/canvas/developer_key_settings_deep_linking.json`);

export function getLessonCollectionURL(lessonCollectionId: number | string = 0, classroom?: Classroom) {
  // if classroom is present, use lesson_collections. See Router.tsx for the difference between
  // 'lesson_collections/:lessonCollectionId' and 'lessons/:lessonCollectionId',
  const collectionsPath = classroom ? 'lesson_collections' : 'lessons';

  const url = new URL(`${window.location.origin}/${collectionsPath}/${lessonCollectionId}`);

  // add classroom details in URL if classroom is present
  const classroomProvider = classroom?.external_classroom_provider;
  if (classroom) {
    url.searchParams.append('classroom_id', classroom.id.toString());
    if (classroomProvider) url.searchParams.append('provider', classroomProvider);
    if (classroom?.external_classroom_id) {
      url.searchParams.append('google_classroom_id', classroom.external_classroom_id);
    }
  }

  return url.toString();
}

/**
 * This function handles build of the video url that can be utilized by a <video /> tag for a segment
 *
 * This constructs a video url that has a range if it is the first segment
 * and not if it isn't. the issue we have is that the only way to get a
 * video started at a start_time > 0 is by setting the range attributes. but
 * change re-assigns the videoUrl state variable which then causes a flicker
 */
export function buildSegmentVideoURL(url: string, start: number, end: number) {
  let urlWithParams = '';
  const startParam = start !== 0 ? `${start}` : '';
  const endParam = end !== 99999 ? `${end}` : '';
  if (startParam === '' && endParam === '') {
    urlWithParams = url;
  } else if (startParam !== '' && endParam !== '') {
    urlWithParams = `${url}#t=${startParam},${endParam}`;
  } else if (startParam !== '' && endParam === '') {
    urlWithParams = `${url}#t=${startParam}`;
  } else if (startParam === '' && endParam !== '') {
    urlWithParams = `${url}#t=0,${endParam}`;
  }
  return urlWithParams;
}

/**
 * Create a segment video URL with end time 99999
 *
 * Purpose of this function is to eliminate the need of adding the comment that explains why this is needed shown below.
 */
export function buildSegmentVideoUrlWithDefiniteEnding(segment: LessonSegment) {
  return buildSegmentVideoURL(
    segment.video_url || '',
    segment.start_time,
    // make the end_time always 99999. this works because we are making a big
    // assumption that if you resume a segment or start a video we will
    // never need to go back to a section that is before the start_time.
    99999,
  );
}

export type URLParamValue = string | number | boolean;
/**
 * Replaces the parameters in a URL path template with the provided values.
 * Given a URL path like '/users/:userId', and a values object like { userId: 123 },
 * this function will return '/users/123'.
 */
export function replaceURLPathParams(pathTemplate: string, values?: Record<string, URLParamValue>) {
  if (!values) return pathTemplate;
  if (isEmpty(values)) return pathTemplate;

  return pathTemplate.replace(/(:\w+)/g, (_, key) => {
    // key is the parameter name with the colon (e.g., ':resource_id')
    const paramName = key.slice(1); // Remove the colon from the parameter name, resource_id
    const paramValue = values[paramName]; // Find the value for the parameter name
    if (!paramValue) {
      console.warn(`urlUtils.replaceURLPathParams - No value provided for path parameter ${paramName}`);
    }
    return paramValue ? paramValue.toString() : '';
  });
}
