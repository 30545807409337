// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CanvasListModules renders the list of modules in a Canvas course 1`] = `
<DocumentFragment>
  <h1>
    Course 1
  </h1>
  .emotion-0 {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.emotion-0 caption {
  padding: 16px;
  color: #1C1B1FB3;
  text-align: left;
  caption-side: bottom;
}

.emotion-1 {
  display: table-header-group;
  background-color: #FCFAFD;
}

.emotion-2 {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}

.emotion-2.MuiTableRow-hover:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.emotion-2.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-2.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

.emotion-3 {
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(250, 249, 250, 1);
  text-align: left;
  padding: 16px;
  color: #1C1B1F;
  line-height: 1.5rem;
  font-weight: 500;
  border-bottom-color: #D6D5DD;
  padding: 8px 16px;
  height: 56px;
  padding: 12px 16px;
  height: auto;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-4 {
  display: table-row-group;
}

.emotion-6 {
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(250, 249, 250, 1);
  text-align: left;
  padding: 16px;
  color: #1C1B1F;
  border-bottom-color: #D6D5DD;
  padding: 8px 16px;
  height: 56px;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: transparent;
  }
}

.emotion-7.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-8 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-9 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<table
    class="MuiTable-root emotion-0"
  >
    <thead
      class="MuiTableHead-root emotion-1"
    >
      <tr
        class="MuiTableRow-root MuiTableRow-head emotion-2"
      >
        <th
          class="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeMedium emotion-3"
          scope="col"
        >
          Module Name
        </th>
      </tr>
    </thead>
    <tbody
      class="MuiTableBody-root emotion-4"
    >
      <tr
        class="MuiTableRow-root emotion-2"
      >
        <td
          class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium emotion-6"
        >
          <button
            aria-expanded="false"
            aria-haspopup="true"
            aria-label="menu"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-7"
            data-testid="kyron-menu-button"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              aria-label="Enhance Module 1"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-8"
              data-mui-internal-clone-element="true"
              data-testid="AddIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-9"
            />
          </button>
          Module 1
        </td>
      </tr>
      <tr
        class="MuiTableRow-root emotion-2"
      >
        <td
          class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium emotion-6"
        >
          <button
            aria-expanded="false"
            aria-haspopup="true"
            aria-label="menu"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-7"
            data-testid="kyron-menu-button"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              aria-label="Enhance Module 2"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-8"
              data-mui-internal-clone-element="true"
              data-testid="AddIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-9"
            />
          </button>
          Module 2
        </td>
      </tr>
    </tbody>
  </table>
</DocumentFragment>
`;
