import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Collapse, IconButton, Modal, Paper, Typography } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { createConsumer } from '@rails/actioncable';
import { useFeatures } from '../../FeaturesContext';

const CollapsibleContent = ({ label, content }: { label: string; content: string }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Paper
      sx={{
        p: 3,
        borderRadius: 1,
        backgroundColor: t => t.palette.divider,
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography component='h3' fontWeight='bold'>
          {label}
        </Typography>
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={{ p: 2, maxHeight: '300px', overflowY: 'auto' }}>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{content}</Typography>
        </Box>
      </Collapse>
    </Paper>
  );
};

type Message = {
  label: string;
  event: string;
  data?: string;
};

type LabeledContent = {
  [label: string]: string;
};

export function DebugStreamsModal() {
  const { show_debug_streams_modal: enabled } = useFeatures();
  const { lessonId } = useParams<{ lessonId: string }>();
  const [labeledContent, setLabeledContent] = useState<LabeledContent>({});
  const [showModal, setShowModal] = useState(false);

  const handleReceived = useCallback((message: Message) => {
    if (message.event === 'text_chunk' && message.data) {
      setLabeledContent(prevContent => ({
        ...prevContent,
        [message.label]: (prevContent[message.label] || '') + message.data,
      }));
    }
  }, []);

  useEffect(() => {
    if (!lessonId || !enabled) return;

    const consumer = createConsumer('/cable');
    const subscription = consumer.subscriptions.create(
      { channel: 'LessonDebugStreamingChannel', lesson_id: lessonId },
      {
        received: (data: string) => {
          const message: Message = JSON.parse(data);
          handleReceived(message);

          if (message.event === 'end_stream') {
            subscription.unsubscribe();
          }
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [enabled, lessonId, handleReceived]);

  if (!lessonId) {
    return <Typography>No lesson ID provided</Typography>;
  }

  return enabled ? (
    <Box>
      <IconButton onClick={() => setShowModal(true)}>
        <ManageSearchIcon />
      </IconButton>

      <Modal open={showModal} onClose={() => setShowModal(false)} aria-labelledby='debug-stream-modal'>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 1200,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <Typography id='debug-stream-modal' component='h1' gutterBottom sx={{ mb: 3 }}>
            Debugging Streams for Lesson {lessonId}
          </Typography>
          {Object.entries(labeledContent).map(([label, content]) => (
            <CollapsibleContent key={label} label={label} content={content} />
          ))}
        </Box>
      </Modal>
    </Box>
  ) : null;
}
