import { useEffect, useState } from 'react';
import { useUserNotifications } from '../../channels/user_notifications';
import { AppCtx, AppState, initialBackgroundTasks } from './index';

/**
 * Initializes the app context
 */
export const useInitAppContext = () => {
  // APP STATE
  const [appState, $$setAppState] = useState<AppState>({
    notifications: {
      backgroundTasks: initialBackgroundTasks,
    },
  });

  // For DEBUGGING
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (process.env.NODE_ENV === 'development') (window as any).appState = appState;

  // This is a wrapper to make sure the appState is always updated with the new state and never overridden entirely
  function setAppState(newState?: Partial<AppState> | ((prevState: AppState) => Partial<AppState>)) {
    // Handle the callback function case
    if (typeof newState === 'function') return $$setAppState(p => ({ ...p, ...newState(p) }));
    // Handle the object case
    $$setAppState(p => ({ ...p, ...newState }));
  }

  const data = useUserNotifications();
  const stringifiedData = JSON.stringify(data); // for deep comparison for change detection
  useEffect(setUserNotifications, [data, stringifiedData]);
  function setUserNotifications() {
    // TODO(Ege): We need a more sophisticated setting mechanism here. Probably by adjusting the data
    //  we receive and having a "type" field in it to determine where in the user notifications to set it.
    //  e.g. { type: 'backgroundTasks', data }
    //  Otherwise, we are risking to override some notifications with the new ones. Especially if we receive different
    //  types of notifications in data.
    setAppState(prev => ({ notifications: { ...prev.notifications, ...data } }));
  }

  return {
    appState,
    notifications: appState.notifications,
    setAppState,
  } satisfies AppCtx;
};
