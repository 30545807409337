import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Role } from 'controllers/types';

export function RolesFormControl({
  allRoles,
  roles,
  setRoles,
}: {
  allRoles: Role[];
  roles: Role[];
  setRoles: (roles: Role[]) => void;
}) {
  const handleRoleChange = (role: Role, active: boolean) => {
    const newRoles = Array.from(roles);
    if (active) {
      newRoles.push(role);
    } else {
      newRoles.splice(
        newRoles.findIndex(r => r.name === role.name),
        1,
      );
    }

    setRoles(newRoles);
  };

  return (
    <>
      {allRoles.map(role => (
        <FormControlLabel
          control={
            <Checkbox
              checked={roles.some(r => r.name === role.name)}
              onChange={(e, checked) => {
                handleRoleChange(role, checked);
              }}
              data-testid='user-role-checkbox'
            />
          }
          label={role.name}
          key={role.name}
        />
      ))}
    </>
  );
}
