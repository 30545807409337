import { createContext, useContext } from 'react';
import { User } from 'controllers/types';

export type UserCtx = {
  isPlatformAdmin: boolean;
  isOrgAdmin: boolean;
  isTeacher: boolean;
  isFinanceOps: boolean;
  isActiveOrgKyronDemos: boolean;
  user: User | null;
};

// Export this function to make testing much easier.
export const makeUserContext = (user: User | null = null): UserCtx => {
  const roleNames = user?.roles?.map(role => role.name) || [];
  // Platform admins automatically have all roles.
  // Org admins automatically have all roles other than platform admin
  // We've embedded that logic here so the rest of our code doesn't need to
  // think about "role hierarchy" and can just think about the most specific
  // role required for access.
  const isPlatformAdmin = roleNames.includes('admin');
  const isOrgAdmin = isPlatformAdmin || roleNames.includes('org_admin');
  const isTeacher = isOrgAdmin || roleNames.includes('teacher');
  const isFinanceOps = roleNames.includes('finance_ops');
  const isActiveOrgKyronDemos = user?.active_organization?.id === 2;

  return {
    isPlatformAdmin,
    isOrgAdmin,
    isTeacher,
    isFinanceOps,
    isActiveOrgKyronDemos,
    user,
  };
};

export const UserContext = createContext(makeUserContext(null));
export const UserProvider = UserContext.Provider;

export const useUserContext = () => useContext(UserContext);
