import React, { useState, CSSProperties } from 'react';

import { Card, CardHeader, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import { Misconception } from 'controllers/types';
import { SingleMisconception } from './SingleMisconception';

const styles = {
  misconceptionCardField: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export function LessonMisconception({
  misconception,
  handleMisconceptionDelete,
  handleMisconceptionUpdate,
}: {
  misconception: Misconception;
  handleMisconceptionDelete: (signedId: number) => void;
  handleMisconceptionUpdate: (misconception: Misconception) => void;
}) {
  const [editable, setEditable] = useState<boolean>(false);

  const handleMisconceptionEditCancel = () => {
    setEditable(false);
  };

  function handleMisconceptionEdit(editedMisconception: Misconception) {
    if (editable) handleMisconceptionUpdate(editedMisconception);
    setEditable(!editable);
  }

  const cardHeaderActions = (
    <CardHeader
      action={
        <>
          <IconButton
            data-testid='misconception-edit'
            aria-label='edit misconception'
            onClick={() => setEditable(true)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            data-testid='misconception-delete'
            aria-label='delete misconception'
            onClick={() => handleMisconceptionDelete(misconception.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      }
    />
  );

  return (
    <Card style={styles.misconceptionCardField as CSSProperties}>
      {!editable && <CardHeader action={cardHeaderActions} />}
      <SingleMisconception
        edit={editable}
        misconception={misconception}
        handleFormSubmit={handleMisconceptionEdit}
        handleFormCancel={handleMisconceptionEditCancel}
      />
    </Card>
  );
}
