/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Collapse, Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { KyronClient } from '../utils/KyronClient';
import { AllCourses } from './AllCourses';
import { NewCourse } from './NewCourse';
import { moveObject } from '../utils/arrayUtils';

const styles = {
  Coursediv: {
    display: 'flex',
    justifyContent: 'center',
  },
  Coursebox: {
    flexGrow: '1',
  },
  Coursebuttondiv: {
    margin: '10px 0px',
  },
  Coursecollapse: {
    width: '50%',
  },
  PositionButton: {
    margin: '0px 5px',
  },
};

export const CourseEditor = () => {
  const { enqueueSnackbar } = useSnackbar();
  const client = new KyronClient();
  const [courses, setCourses] = useState([]);
  const [newCourseExpanded, setNewCourseExpanded] = useState(false);

  const reorderCourses = (course, direction) => {
    const newCourses = moveObject(course, courses, direction);
    newCourses.forEach((c, index) => {
      // eslint-disable-next-line no-param-reassign
      c.position = index + 1;
      updateCourse(course);
    });
  };

  const reorderUnits = (unit, direction) => {
    const course = courses.find(c => c.id === unit.parent_id);
    const newUnits = moveObject(unit, course.children, direction);
    newUnits.forEach((u, index) => {
      // eslint-disable-next-line no-param-reassign
      u.position = index + 1;
    });
    updateCourse(course);
  };

  const handleNewCourseExpandClick = () => {
    setNewCourseExpanded(!newCourseExpanded);
  };

  const handleNewCourseFormSubmit = (handleCourseUpdate, addCourse, course) => {
    const formData = new FormData();
    for (const property in course) {
      if (property !== 'id') {
        formData.append(property, course[property]);
      }
    }

    client
      .submitJsonDataWithError('/api/v1/lesson_containers', 'POST', formData)
      .then(newCourse => {
        addCourse(newCourse);
        enqueueSnackbar('Course Created');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    client
      .getDataWithError('/api/v1/lesson_containers/get_tiered_containers.json')
      .then(data => {
        setCourses(data);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewCourse = newCourse => {
    setCourses(courses.concat(newCourse));
  };

  const handleCourseDelete = id => {
    const course = courses.find(c => c.id === id);
    client
      .deleteDataWithError(`/api/v1/lesson_containers/${id}`)
      .then(() => {
        if (course.children?.length > 0 || course.lessons?.length > 0) {
          enqueueSnackbar('Course cannot be deleted because it has contents', { variant: 'error' });
        } else {
          deleteCourse(id);
          enqueueSnackbar('Course Deleted');
        }
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  function deleteCourse(id) {
    const newCourses = courses.filter(course => course.id !== id);
    setCourses(newCourses);
  }

  const handleCourseUpdate = (updateFn, course) => {
    const formData = new FormData();
    for (const property in course) {
      if (property !== 'id') {
        formData.append(property, course[property]);
      }
    }

    client
      .submitJsonDataWithError(`/api/v1/lesson_containers/${course.id}`, 'PUT', formData)
      .then(updatedCourse => {
        updateFn(updatedCourse);
        enqueueSnackbar('Course Updated');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  function updateCourse(updatedCourse) {
    let newCourses = courses.filter(course => course.id !== updatedCourse.id);
    newCourses.push(updatedCourse);
    newCourses = newCourses.sort((a, b) => a.position - b.position);
    setCourses(newCourses);
  }

  const handleUpdatePositionsClick = () => {
    const formData = new FormData();
    courses.forEach((course, index) => {
      formData.append('lesson_containers[]', JSON.stringify(courses[index]));
    });
    client
      .submitJsonDataWithError('/api/v1/lesson_containers/update_positions', 'PUT', formData)
      .then(data => {
        const newCourses = data.filter(container => container.parent_id == null);
        newCourses.forEach(c => {
          // eslint-disable-next-line no-param-reassign
          c.children = data.filter(container => container.parent_id === c.id);
        });
        setCourses(newCourses);
        enqueueSnackbar('Positions Saved');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useTitle('Course Editor');
  useActions(
    <div style={styles.Coursediv}>
      <Button variant='contained' onClick={handleNewCourseExpandClick} icon={AddIcon}>
        New Course
      </Button>
    </div>,
  );

  return (
    <>
      <Box display='flex' justifyContent='flex-end'>
        <Button variant='contained' onClick={handleUpdatePositionsClick} style={styles.PositionButton}>
          Save Positions
        </Button>
      </Box>
      <div style={styles.Coursebuttondiv}>
        <div style={styles.Coursediv}>
          <Collapse in={newCourseExpanded} style={styles.Coursecollapse} timeout='auto' unmountOnExit>
            <NewCourse
              addCourse={addNewCourse}
              handleFormSubmit={handleNewCourseFormSubmit}
              handleFormCancel={handleNewCourseExpandClick}
              handleCourseUpdate={handleCourseUpdate}
            />
          </Collapse>
        </div>
      </div>
      <AllCourses
        courses={courses}
        handleCourseDelete={handleCourseDelete}
        handleCourseUpdate={handleCourseUpdate}
        updateCourse={updateCourse}
        reorderCourses={reorderCourses}
        reorderUnits={reorderUnits}
      />
    </>
  );
};
