import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  TextField,
  Tab,
  Tabs,
  Link,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getLessonCollectionURL, getLTILink } from 'components/utils/urlUtils';
import {
  usePublishLesson,
  useLessonValidateQuery,
  useDownloadScorm,
  useGetLtiPlatforms,
  useClassrooms,
  useUpdateLessonCollection,
  useLessonQuery,
} from 'controllers/react-query';
import { KyronIntercom } from 'components/utils/KyronIntercom';
import { PublishVisibility, Lesson } from 'controllers/types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { CopyButton } from 'components/CopyButton';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { usePaywall, NavigateToPricingButton } from 'components/Pricing/PaymentContext';
import { AssignableClassroomsList } from 'components/CatalogLessonCard/AssignableClassroomsList';
import { ValidationsDisplay, ValidationErrors } from './ValidationsDisplay';

const DEFAULT_URL = 'https://www.kyronlearning.com';

type ExportCourseDialogProps = {
  lessonId?: number;
  onClose: () => void;
  isProcessingCourse?: boolean;
};
export function ExportCourseDialog({ lessonId, onClose, isProcessingCourse }: ExportCourseDialogProps) {
  const { canShareCourse } = usePaywall();
  const { data: lesson } = useLessonQuery(lessonId);

  const {
    published_lesson_collection: lessonCollection, // only expose if lesson is published
    has_unpublished_changes: hasUnpublishedChanges,
  } = lesson || {};

  const {
    data: lessonValidations,
    refetch: refetchValidations,
    isFetching: loadingValidations,
    error: fetchValidationError,
  } = useLessonValidateQuery(lessonId);
  const { isPending: isPublishPending, error: publishLessonError, mutate: publishLesson } = usePublishLesson();

  const publishAndCloseDialog = () => {
    if (lessonId) {
      KyronEvents.sendEvent(KyronEvents.names.PUBLISH_COURSE, { lesson_id: lessonId });
      publishLesson({ lessonId, payload: { visibility: PublishVisibility.PRIVATE } }, { onSuccess: onClose });
    }
  };

  const failingValidations =
    lessonValidations?.filter(
      validation => !validation.pass && validation.name !== ValidationErrors.UNNORMALIZED_VIDEOS,
    ) || [];
  const hasFailingValidations = failingValidations.length > 0;
  useEffect(() => {
    refetchValidations?.();
  }, [refetchValidations]);

  return (
    <Stack p={2} data-testid='export-course-dialog'>
      <Typography variant='bodyMedium' mb={2}>
        Export your course so you can share it with learners. You can share your course as a link, by downloading a
        SCORM zip file to upload in your LMS, or with an LTI integration in your LMS.
      </Typography>

      {/* Publish */}
      {canShareCourse ? (
        <Button
          onClick={publishAndCloseDialog}
          disabled={
            isProcessingCourse ||
            isPublishPending ||
            loadingValidations ||
            hasFailingValidations ||
            !hasUnpublishedChanges
          }
          startIcon={
            isPublishPending || loadingValidations || isProcessingCourse ? <LoadingIndicator spinnerSize={20} /> : null
          }
          data-testid='publish-button'
        >
          {lessonCollection ? 'Republish' : 'Publish'}
        </Button>
      ) : (
        <NavigateToPricingButton onClick={onClose}>Subscribe to Publish Course</NavigateToPricingButton>
      )}
      {hasUnpublishedChanges ? undefined : (
        <Typography variant='bodySmall' color='text.secondary' align='center'>
          Course is up-to-date
        </Typography>
      )}
      {isProcessingCourse ? (
        <Typography variant='bodySmall' color='text.secondary' align='center'>
          Course is processing
        </Typography>
      ) : undefined}

      {/* Validations */}
      {fetchValidationError && (
        <Box mt={2}>
          <Alert severity='error'>{fetchValidationError.message}</Alert>
        </Box>
      )}
      {publishLessonError && (
        <Box mt={2}>
          <Alert severity='error'>{publishLessonError.message}</Alert>
        </Box>
      )}
      <ValidationsDisplay lessonId={lessonId} failingValidations={failingValidations} disabled={isProcessingCourse} />

      <ShareOptions lesson={lesson} onClose={onClose} disableShare={!canShareCourse} />
    </Stack>
  );
}

function ShareTabPanel({ tabValue, index, children }: { tabValue: number; index: number; children: React.ReactNode }) {
  return (
    <Box hidden={tabValue !== index} aria-label={`tab-option-${index}`} id={`tab-${index}`} role='tabpanel'>
      {tabValue === index && (
        <Stack gap={1} minHeight={200}>
          {children}
        </Stack>
      )}
    </Box>
  );
}

function ShareOptions({
  lesson,
  onClose,
  disableShare,
}: {
  lesson?: Lesson;
  onClose: () => void;
  disableShare: boolean;
}) {
  const [tabValue, setTabValue] = useState(0);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    id: lessonId,
    published_lesson_collection: lessonCollection, // only expose if lesson is published
  } = lesson || {};
  const ltiLink = lessonCollection ? getLTILink(lessonCollection) : DEFAULT_URL;
  const collectionUrl = lessonCollection ? getLessonCollectionURL(lessonCollection.id) : DEFAULT_URL;
  const [loadingGoogleClassroom, setLoadingGoogleClassroom] = useState(false);
  const [needsToReauthenticateGoogle, setNeedsToReauthenticateGoogle] = useState(false);

  const { mutateAsync: getScormFile, isPending: isDownloadingScorm } = useDownloadScorm(lessonId || 0);

  const allowAnonymousLearners = lessonCollection?.allow_anonymous_learners ?? false;
  const { mutate: updateLessonCollection, isPending: isUpdatingLessonCollection } = useUpdateLessonCollection();
  const handleAnonymousLearnersChange = () => {
    if (lessonCollection) {
      updateLessonCollection({
        collectionId: lessonCollection.id,
        lessonId: lesson?.id,
        payload: { ...lessonCollection, allow_anonymous_learners: !allowAnonymousLearners },
      });
    }
  };

  const { data: ltiPlatformData } = useGetLtiPlatforms(1, 1); // just check if there's at least one set up
  const hasSetupLTI = (ltiPlatformData?.lti_platforms?.length ?? 0) > 0;
  const { isFetching: isFetchingClassrooms, isError: isClassroomsError, data: classroomData } = useClassrooms(); // check if the use has any Google Classrooms
  const hasGoogleClassrooms = classroomData?.find(c => c.external_classroom_provider === 'google_classroom') ?? null;

  const handleLtiSettingsClick = () => {
    onClose();
    navigate('/studio/settings/integrations');
  };

  const linkGoogleButton = (message: string) => (
    <Button
      href={`/users/auth/google_classroom?redirect_to=${window.location.pathname}`}
      data-method='post'
      variant='outlined'
      sx={{ whiteSpace: 'nowrap' }}
      onClick={() => setLoadingGoogleClassroom(true)}
      disabled={loadingGoogleClassroom}
      data-testid='google-classroom-auth'
    >
      {message}
    </Button>
  );

  const handleScormDownload = async () => {
    try {
      await getScormFile();
      KyronEvents.sendEvent(KyronEvents.names.DOWNLOAD_SCORM, {
        lesson_collection_id: lessonCollection?.id,
        lesson_name: lessonCollection?.name,
        user_id: user?.id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Stack gap={3} data-testid='share-options'>
      <Divider sx={{ my: 2 }} />
      <Box width='100%'>
        <Tabs
          value={tabValue}
          onChange={(_event, newValue) => setTabValue(newValue)}
          aria-label='Share course options'
          variant='fullWidth'
        >
          <Tab label='Link' disabled={disableShare} />
          <Tab label='SCORM' disabled={disableShare} />
          <Tab label='LTI' disabled={disableShare} />
          <Tab label='Google Classroom' disabled={disableShare} />
        </Tabs>
      </Box>
      <ShareTabPanel tabValue={tabValue} index={0}>
        <Typography variant='labelMedium' color={disableShare ? 'text.disabled' : 'text.primary'}>
          Link
        </Typography>
        <Typography variant='bodyMedium' color={disableShare ? 'text.disabled' : 'text.primary'}>
          Share this link with learners.
        </Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <TextField
            fullWidth
            value={collectionUrl}
            disabled={!lessonCollection || disableShare}
            onFocus={e => e.target.select()}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyButton
                  disabled={!lessonCollection || disableShare}
                  value={collectionUrl}
                  onAnimationEnd={() =>
                    KyronEvents.sendEvent(KyronEvents.names.LINK_SHARE, {
                      lesson_collection_id: lessonCollection?.id,
                      lesson_name: lessonCollection?.name,
                      user_id: user?.id,
                    })
                  }
                />
              ),
            }}
          />
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!allowAnonymousLearners}
              onChange={handleAnonymousLearnersChange}
              disabled={isUpdatingLessonCollection}
            />
          }
          label='Require learners to sign in'
          disabled={!lessonCollection || disableShare}
        />
        {allowAnonymousLearners && (
          <Typography
            variant='bodySmall'
            color={disableShare ? 'text.disabled' : 'text.primary'}
            data-testid='anonymous-learners-warning'
          >
            Any learners with access to this link who do not sign in will be recorded as anonymous in reports.
          </Typography>
        )}
      </ShareTabPanel>
      <ShareTabPanel tabValue={tabValue} index={1}>
        <Typography variant='labelMedium'>SCORM</Typography>
        <Typography variant='bodySmall' color='text.secondary'>
          Download SCORM package and upload it to your LMS.
        </Typography>
        <Button
          variant='outlined'
          startIcon={isDownloadingScorm ? <LoadingIndicator /> : <Download />}
          disabled={!lessonCollection || isDownloadingScorm}
          onClick={handleScormDownload}
        >
          Download SCORM
        </Button>
      </ShareTabPanel>
      <ShareTabPanel tabValue={tabValue} index={2}>
        {!hasSetupLTI && (
          <Alert severity='error' icon={false} sx={{ borderRadius: 1 }} data-testid='lti-warning'>
            <Stack gap={1}>
              <Typography>
                Before you can share via LTI link, you need to set up an LTI integration between Kyron and your LMS.{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component='button'
                  onClick={() => KyronIntercom.showArticle('lti')}
                  sx={{ verticalAlign: 'bottom' }}
                >
                  Learn more here.
                </Link>
              </Typography>
              <Button variant='outlined' onClick={handleLtiSettingsClick}>
                Go to Organization LTI Settings
              </Button>
            </Stack>
          </Alert>
        )}
        <Typography variant='labelMedium'>LTI</Typography>
        <Typography variant='bodyMedium'>
          Use an LTI link to integrate with your LMS. {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component='button' onClick={() => KyronIntercom.showArticle('lti')} sx={{ verticalAlign: 'bottom' }}>
            Learn more.
          </Link>
        </Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <TextField
            fullWidth
            value={ltiLink}
            disabled={!lessonCollection || !hasSetupLTI}
            onFocus={e => e.target.select()}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyButton
                  value={ltiLink}
                  disabled={!lessonCollection}
                  onAnimationEnd={() =>
                    KyronEvents.sendEvent(KyronEvents.names.LTI_LINK_SHARE, {
                      lesson_collection_id: lessonCollection?.id,
                      lesson_name: lessonCollection?.name,
                      user_id: user?.id,
                    })
                  }
                />
              ),
            }}
          />
        </Stack>
      </ShareTabPanel>
      <ShareTabPanel tabValue={tabValue} index={3}>
        <Typography variant='labelMedium'>Google Classroom</Typography>
        {needsToReauthenticateGoogle && (
          <Alert severity='error' icon={false} sx={{ borderRadius: 1 }}>
            <Stack gap={1}>
              <Typography>
                Before you can share via Google Classroom, you need to reauthenticate your Google Classroom integration.
              </Typography>
              {linkGoogleButton('Reconnect Google Classroom')}
            </Stack>
          </Alert>
        )}
        {hasGoogleClassrooms && !needsToReauthenticateGoogle && (
          <>
            <Typography variant='bodyMedium'>Publish or draft courses directly to your Google Classroom.</Typography>
            <AssignableClassroomsList
              lessonCollection={lessonCollection}
              reauthenticate={() => setNeedsToReauthenticateGoogle(true)}
              classrooms={classroomData}
              isFetching={isFetchingClassrooms}
              isError={isClassroomsError}
            />
          </>
        )}
        {!hasGoogleClassrooms && !needsToReauthenticateGoogle && (
          <Alert severity='error' icon={false} sx={{ borderRadius: 1 }} data-testid='google-classroom-warning'>
            <Stack gap={1}>
              <Typography>
                Before you can share via Google Classroom, you need to set up a Google Classroom integration between
                Kyron and your Google account.
              </Typography>
              {linkGoogleButton('Connect Google Classroom')}
            </Stack>
          </Alert>
        )}
      </ShareTabPanel>
    </Stack>
  );
}
