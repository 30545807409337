import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { GoogleClassroomSignInDialog } from './GoogleClassroomSignInDialog';
import { useClassroomCredentials } from '../../controllers/react-query/classroomCredentialsHooks';

/**
 * This component is responsible for checking if the student is authorized to view the lesson
 * and enrolled in the classroom. If not, it will show a dialog to sign in.
 *
 * This hooke will NOT render if there is not a google_classroom_id search param in the URL.
 *
 * @param showUIMessages - If true, it will show a snackbar message to the user about their status
 */
export function useClassroomStudentValidator({ showUIMessages = false } = {}) {
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    googleClassroomData,
    isFetchingGoogleClassroomData,
    isStudentEnrolled,
    isGoogleClassStudentAuthenticated,
    isKyronClassStudentAuthenticated,
    isKyronClassroom,
    googleClassroomId,
    provider,
  } = useClassroomCredentials();

  useEffect(showGoogleSignInDialog, [isGoogleClassStudentAuthenticated]);
  function showGoogleSignInDialog() {
    if (!isGoogleClassStudentAuthenticated) {
      setSignInDialogOpen(true);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(showUIMessage, [
    isKyronClassStudentAuthenticated,
    isGoogleClassStudentAuthenticated,
    isFetchingGoogleClassroomData,
  ]);
  function showUIMessage() {
    const studentNote = isStudentEnrolled
      ? 'You are enrolled in this classroom and your work will be shown to your teacher.'
      : 'You are not enrolled in this classroom and your work will not be shown to your teacher.';
    if (
      showUIMessages &&
      !isFetchingGoogleClassroomData &&
      (isKyronClassStudentAuthenticated || isGoogleClassStudentAuthenticated)
    ) {
      enqueueSnackbar(studentNote);
    }
  }

  return {
    signInDialog: (
      // Wrapper box to make sure this dialog is being rendered full page
      <Box sx={{ height: '100vh', width: '100vw' }}>
        <GoogleClassroomSignInDialog
          actAsPage
          open={signInDialogOpen}
          classroomId={googleClassroomData?.classroomId || ''}
          googleClassroomId={googleClassroomId || ''}
          provider={provider || ''}
        />
      </Box>
    ),
    isGoogleClassStudentAuthenticated,
    isFetchingGoogleClassroomData,
    isKyronClassroom,
    googleClassroomData,
    shouldRequireGoogleLogin: isGoogleClassStudentAuthenticated !== null && !isGoogleClassStudentAuthenticated,
  };
}
