import React from 'react';
import { Button } from '@mui/material';
import { useFeatures } from 'components/FeaturesContext';
import { useModal } from 'components/utils/ModalContext';
import { Lesson } from 'controllers/types';
import { KyronEvents } from 'components/utils/KyronEvents';
import { ExportCourseDialog } from './ExportCourseDialog';
import { PublicPrivatePublishDialog } from './PublicPrivatePublishDialog';

export function PublishButton({ lesson, disabled }: { lesson?: Lesson; disabled?: boolean }) {
  const { openModal, closeModal } = useModal();
  const { new_plg_flow: isNewPlgFlow } = useFeatures();

  function openPublishModal() {
    openModal({
      id: 'publish-course-modal',
      title: 'Publish Course?',
      content: <PublicPrivatePublishDialog lessonId={Number(lesson?.id)} onClose={() => closeModal()} />,
    });
  }

  function openExportModal() {
    KyronEvents.sendEvent(KyronEvents.names.CLICK_EXPORT, { lesson_id: lesson?.id });
    openModal({
      id: 'export-modal',
      title: 'Export course',
      content: <ExportCourseDialog lessonId={lesson?.id} onClose={() => closeModal()} isProcessingCourse={disabled} />,
    });
  }

  return isNewPlgFlow ? (
    <Button onClick={openExportModal} data-testid='export-course'>
      Export Course
    </Button>
  ) : (
    <Button onClick={openPublishModal} disabled={disabled}>
      Publish Course
    </Button>
  );
}
