/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import ReactGA from 'react-ga4';
import { KyronClient } from './KyronClient';

declare global {
  interface Window {
    kyronEventOptions: object;
  }
}

export enum EventNames {
  // User events
  LOGIN = 'user_login',
  LOGOUT = 'user_logout',

  // Studio events
  UPLOAD_BRAND_IMAGE = 'upload_brand_image',
  OPEN_COURSE_CREATOR = 'open_course_creator',

  // App navigation & homepage events
  CLICKED_ACCOUNT_MENU = 'click_account_menu',
  SEARCHED_MARKETPLACE = 'search_marketplace',
  PREVIEW_COURSE = 'preview_course',
  CREATE_FREE_COURSE = 'create_free_course',
  USE_EXAMPLE_PROMPT = 'use_example_prompt',
  CREATE_COURSE_FROM_TITLE = 'create_course_from_title',
  SUBMIT_CONTACT_US = 'submit_contact_us',

  // Course creation events
  SUBMIT_CREATE_FORM = 'course_create_form_submit',
  UPLOAD_AUX_CONTENT = 'upload_content',
  APPROVE_LEARNING_OBJECTIVES = 'approve_learning_objectives',
  RECORD_VIDEO = 'record_video',
  SAVED_VIDEO_RECORDING = 'saved_video_recording',
  DELETE_SECTION = 'delete_section',
  DELETE_SEGMENT = 'delete_segment',
  ADD_SECTION = 'add_section',
  ADD_SEGMENT = 'add_segment',
  EDIT_STEP_TEXT = 'edit_step_text',
  EDIT_QUESTION_TEXT = 'edit_question_text',
  EDIT_ANSWER_TEXT = 'edit_answer_text',
  EDIT_MC_OPTION_TEXT = 'edit_mc_option_text',
  EDIT_MC_RESPONSE_TEXT = 'edit_mc_response_text',
  UPLOAD_SEGMENT_VIDEO = 'upload_video',
  UPLOAD_SEGMENT_IMAGE = 'upload_image',
  ADD_LANGUAGE = 'add_language',
  PREVIEW_CREATED_COURSE = 'preview_created_course',
  PUBLISH_COURSE = 'publish_course',
  CLICK_EXPORT = 'click_export',

  // Course sharing events
  EMAIL_SHARE = 'share_via_email',
  FACEBOOK_SHARE = 'share_via_facebook',
  X_SHARE = 'share_via_x',
  WHATSAPP_SHARE = 'share_via_whatsapp',
  LINK_SHARE = 'copy_sharable_link',
  DOWNLOAD_SCORM = 'download_scorm',
  LTI_LINK_SHARE = 'copy_lti_link',

  // Player events
  START_COURSE = 'start_course',
  COMPLETE_COURSE = 'complete_course',
  TOGGLE_TOC = 'toggle_toc',
  TOGGLE_TRANSCRIPT = 'toggle_transcript',
  SELECT_MCQ_OPTION = 'select_mcq_option',
  SELECT_EQUATION_MCQ_OPTION = 'select_equation_mcq_option',
  AUDIO_UTTERANCE = 'audio_utterance',
  TEXT_INPUT = 'text_input',
  EQUATION_INPUT = 'equation_input',
  COMPLETE_OEQ = 'complete_oeq',
  STOP_ASSISTANT = 'stop_assistant',
  SWITCH_TUTOR = 'switch_tutor',
  WAIT_TIMEOUT = 'wait_timeout',
  PLAYING_SEGMENT = 'playing_segment',
  CHANGE_PLAYBACK_RATE = 'change_playback_rate',
  USE_SCRUBBER = 'use_scrubber',
  CLICK_BACK = 'click_back',
  CHANGE_VOLUME = 'change_volume',
  CLICK_PLAY_PAUSE = 'click_play_pause',

  // Chat Feedback events
  THUMBS_DOWN = 'ai_thumbs_down',
  UNTHUMBS_DOWN = 'ai_unthumbs_down',
  UPDATE_CHAT_MESSAGE = 'update_chat_message',

  // Test Event
  FOO = 'foo',
}

window.kyronEventOptions = {};

export const KyronEvents = {
  sendEvent: (e: EventNames, o = {}) => {
    // send event to Google Analytics
    ReactGA.gtag('event', e, o);

    // send event to Kyron
    const options: { [key: string]: string } = { ...o, ...window.kyronEventOptions };
    const formData = new FormData();
    for (const property in options) {
      formData.append(property, options[property]);
    }
    formData.append('name', e);

    const client = new KyronClient();
    return client.submitJsonDataWithError('/api/v1/main/log_event.json', 'POST', formData);
  },
  resetOptions: () => {
    window.kyronEventOptions = {};
  },
  addOptions: (o = {}) => {
    window.kyronEventOptions = { ...o, ...window.kyronEventOptions };
  },
  names: EventNames,
};
