/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Box, Button, InputLabel, MenuItem, Select } from '@mui/material';
import Papa from 'papaparse';
import { useSnackbar } from 'notistack';
import { getActiveLessons } from 'controllers/lessons';
import { Lesson } from 'controllers/types';
import { KyronClient } from 'components/utils/KyronClient';
import { useTitle } from 'components/StudioLayout/StudioLayout';
import { DataGrid } from '../common/DataGrid';

const styles = {
  LessonDropdown: {
    width: '20%',
  },
  ResultsTable: {
    height: '100vh',
    width: '100%',
  },
};

export const TestRunner = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [lessonId, setLessonId] = useState('');
  const [allLessons, setAllLessons] = useState<Lesson[]>([]);

  const [expectedResults, setExpectedResults] = useState<any>([]); // TODO: bad, type this
  const [resultRows, setResultRows] = useState<any>([]); // TODO: bad, type this
  const client = new KyronClient();

  useEffect(() => {
    getActiveLessons()
      .then(({ lessons }) => {
        setAllLessons(lessons);
        setLessonId(String(lessons[0].id));
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'expectedSegment', headerName: 'Expected', minWidth: 300 },
    { field: 'actualSegment', headerName: 'Actual', minWidth: 300 },
    { field: 'status', headerName: 'Status', minWidth: 120 },
  ];

  const handleUpload = (e: any) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        setExpectedResults(
          results.data.map((expectedResult: any) => ({
            segmentName: expectedResult['Expected Segment'],
            inputType: expectedResult['Input Type'],
            input: expectedResult.Input,
          })),
        );

        enqueueSnackbar('CSV Successfully Uploaded');
      },
    });
  };

  const runTest = () => {
    const formData = new FormData();
    formData.append('lesson_id', lessonId);
    formData.append('restart', 'true');
    // TODO: move client call into /controllers
    client
      .submitJsonDataWithError('/api/v1/lesson_instances', 'POST', formData)
      .then(async data => {
        const firstSegment = data as { lesson_instance: any; lesson_segment_instance: any; lesson_segment: any }; // TODO: bad, type this
        // Parse the result of the first lesson segment
        const sessionId = firstSegment.lesson_instance.session_id;
        let currentSegmentInstanceId = firstSegment.lesson_segment_instance.id;
        const lessonInstanceId = firstSegment.lesson_instance.id;
        let status = expectedResults[0].segmentName === firstSegment.lesson_segment.name ? 'Pass' : 'Fail';
        setResultRows([
          {
            id: resultRows.length,
            expectedSegment: expectedResults[0].segmentName,
            actualSegment: firstSegment.lesson_segment.name,
            status,
          },
        ]);

        // Iterate through each expected segment and print results
        for (let i = 1; i < expectedResults.length; i++) {
          const intentInstance: Record<string, any> /* TODO: Bad, type this */ = {
            lesson_segment_instance_id: currentSegmentInstanceId,
            sessionId,
          };
          if (expectedResults[i - 1].inputType) {
            intentInstance[expectedResults[i - 1].inputType] = JSON.stringify(expectedResults[i - 1].input);
          }
          const form = new FormData();
          for (const property in intentInstance) {
            form.append(property, intentInstance[property]);
          }
          // TODO: move client call into /controllers
          // eslint-disable-next-line no-await-in-loop
          const nextSegment: any = await client.submitJsonDataWithError(
            `/api/v1/lesson_instances/${lessonInstanceId}/next_lesson_segment`,
            'PUT',
            form,
          );
          currentSegmentInstanceId = nextSegment?.lesson_segment_instance.id;
          status = expectedResults[i].segmentName === nextSegment?.lesson_segment.name ? 'Pass' : 'Fail';
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          setResultRows((rows: any /* TODO: bad, type this */) => [
            ...rows,
            {
              id: i,
              expectedSegment: expectedResults[i].segmentName,
              actualSegment: nextSegment?.lesson_segment.name,
              status,
            },
          ]);
        }
        enqueueSnackbar('Successfully ran test!');
      })
      .catch(error => {
        enqueueSnackbar(`Failed to run test: ${error.message}`, {
          variant: 'error',
        });
      });
  };

  useTitle('Test Runner');

  return (
    <>
      <InputLabel id='lesson-id-input'>Choose a Lesson ID</InputLabel>
      <Select
        id='lesson-id'
        label='Lesson ID'
        data-testid='lesson-id'
        value={lessonId}
        onChange={e => setLessonId(e.target.value)}
        sx={styles.LessonDropdown}
      >
        {allLessons.map(lesson => (
          <MenuItem key={lesson.id} value={lesson.id}>
            {lesson.name}
          </MenuItem>
        ))}
      </Select>
      <Button aria-label='Upload CSV File' variant='contained' component='label'>
        <input type='file' accept='.csv' data-testid='csv-upload' onChange={handleUpload} />
      </Button>
      <Button aria-label='run test' variant='contained' onClick={runTest}>
        Run test
      </Button>
      <Box sx={styles.ResultsTable}>{resultRows.length > 0 && <DataGrid rows={resultRows} columns={columns} />}</Box>
    </>
  );
};
