import React from 'react';
import { Box, TextField, Stack, Typography, styled } from '@mui/material/';
import { Row } from 'components/Row/Row';
import { CopyButton } from 'components/CopyButton';
import { Player } from 'components/Player/Player';

const VideoBorder = styled(Box)(() => ({
  position: 'relative',
  width: '80%',
  height: 'calc(90vh)',
  border: `8px solid red`,
  p: 1,
}));

export const PreviewPlayer = () => (
  <Stack alignItems='center' gap={1} mt={2} justifyContent='space-between'>
    <Row width='90%' justifyContent='space-between'>
      <Typography variant='headlineLarge' flexGrow={1}>
        Course Preview
      </Typography>
      <TextField
        value={window.location.href}
        label='Share Course Preview'
        onFocus={e => e.target.select()}
        size='small'
        fullWidth
        sx={{ maxWidth: 500 }}
        InputProps={{
          readOnly: true,
          endAdornment: <CopyButton value={window.location.href} />,
        }}
      />
    </Row>
    <VideoBorder>
      <Box height='100%' width='100%'>
        <Player restart />
      </Box>
    </VideoBorder>
  </Stack>
);
