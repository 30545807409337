import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { LoaderFunctionArgs, Outlet, useLoaderData } from 'react-router-dom';
import { KyronLayoutContent } from 'components/KyronLayout/KyronLayout';
import {
  getMarketplaceLessonContainers,
  getDemoLessonContainers,
  getPublicLessonContainers,
  getMathLessonContainers,
} from 'controllers/lessonContainers';
import { LessonContainer } from 'controllers/types';
import { NavItemButton } from 'components/NavItemButton/NavItemButton';

export async function loader({ request }: LoaderFunctionArgs): Promise<LessonContainer[]> {
  const url = new URL(request.url);
  if (url.pathname === '/demo') {
    return await getDemoLessonContainers();
  }
  if (url.pathname === '/marketplace') {
    return await getMarketplaceLessonContainers();
  }
  if (url.pathname === '/math') {
    return await getMathLessonContainers();
  }
  return await getPublicLessonContainers();
}

export function CatalogLayout() {
  const courses = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  return (
    <KyronLayoutContent>
      <Stack direction='row' gap={10}>
        <Box width='220px'>
          <h2 className='headline-small' style={{ paddingLeft: '16px' }}>
            Units
          </h2>
          <Stack spacing={0.5} component='nav' sx={{ typography: 'labelLarge' }} data-testid='units-list'>
            {courses.map(course =>
              course.children?.map(unit => (
                <NavItemButton
                  key={unit.id}
                  to={`/k12/units/${unit.id}`}
                  label={unit.name}
                  additionalText={`${unit.lesson_collection_count} Lessons`}
                />
              )),
            )}
          </Stack>
        </Box>
        <Box flex='1'>
          <Outlet />
        </Box>
      </Stack>
    </KyronLayoutContent>
  );
}
