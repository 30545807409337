import { useMemo } from 'react';
import { capitalize } from '@mui/material';
import { CategorizedTasks, useAppContext } from '../AppContext';
import { BackgroundTask, LessonInstance, LessonSegment } from '../../controllers/types';

const tasksOfLesson = (lessonId: string | number | undefined) => (task: BackgroundTask) => {
  if (!lessonId) return false;

  if (task.task_entity_type === 'Lesson') {
    return task.task_entity_id && task.task_entity_id.toString() === lessonId?.toString?.();
  }

  if (task.task_entity_type === 'LessonSegment' || task.task_entity_type === 'LessonInstance') {
    const taskEntity = task.task_entity as LessonSegment | LessonInstance;
    return taskEntity?.lesson_id && taskEntity.lesson_id.toString() === lessonId?.toString?.();
  }

  return false;
};

// Example return value:
// ```json
// {
//   active: { lessonPlanning: [task1, task2], ... },
//   failed: { lessonPlanning: [], ... },
//   isLessonPlanningInProgress: true,
//   isLessonPlanningFailed: false,
//   ...
// }
// ```
//
export function useLessonBTWatcher(lessonId: string | number | undefined) {
  const { notifications } = useAppContext();

  const btNotifications = notifications?.backgroundTasks || {};

  const active = useMemo(
    () => filterLessonTasks(lessonId, btNotifications?.active) || {},
    [btNotifications?.active, lessonId],
  );

  const failed = useMemo(
    () => filterLessonTasks(lessonId, btNotifications?.failed) || {},
    [btNotifications?.failed, lessonId],
  );

  return {
    active,
    failed,
    ...getProgressAndFailureFlags(active, failed),
  };
}

// Takes in a lessonId and a categorizedTasks object and returns a new categorizedTasks object with only the tasks that
// belong to the lesson with the given lessonId.
// So, the output will look exactly like this input (CategorizedTask) but only with the specified lesson's tasks
export function filterLessonTasks(lessonId: string | number | undefined, categorizedTasks: CategorizedTasks) {
  if (!categorizedTasks) return {};
  if (!lessonId) return categorizedTasks;

  const filteredCategorizedTasks: CategorizedTasks = { ...categorizedTasks };
  Object.entries(categorizedTasks).forEach(([key, tasks]) => {
    filteredCategorizedTasks[key as keyof CategorizedTasks] = tasks.filter(tasksOfLesson(lessonId));
  });
  return filteredCategorizedTasks;
}

// Takes in the active and failed tasks and returns an object with flags to indicate if there are any active or
// failed tasks.
// Output will be a boolean flag for progress and failure for each category of tasks.
// For example, if active tasks have a category called 'lessonPlanning', this function will return an object with
// isLessonPlanningInProgress and isLessonPlanningFailed flags.
function getProgressAndFailureFlags(active: CategorizedTasks, failed: CategorizedTasks) {
  const flags = {} as Record<`is${Capitalize<keyof CategorizedTasks>}${'InProgress' | 'Failed'}`, boolean>;
  Object.keys(active).forEach(key => {
    const typedKey = key as keyof CategorizedTasks;
    const capitalizedTypedKey = capitalize(key) as Capitalize<keyof CategorizedTasks>;
    flags[`is${capitalizedTypedKey}InProgress`] = (active[typedKey]?.length || 0) > 0;
    flags[`is${capitalizedTypedKey}Failed`] = (failed[typedKey]?.length || 0) > 0;
  });

  return flags;
}
