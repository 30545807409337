import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Tooltip, ToggleButton, Drawer, styled, useMediaQuery, Theme, IconButton } from '@mui/material/';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSnackbar } from 'notistack';
import {
  Lesson,
  TOCItem,
  LessonSegment,
  NextLessonSegmentForm,
  NextLessonSegmentResponse,
  GotoLessonInstanceResponse,
} from 'controllers/types';
import { createLessonInstance, gotoLessonSegment, nextLessonSegment } from 'controllers/lessonInstances';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { HelpOutline, InfoOutlined, TextSnippet } from '@mui/icons-material';
import { VideoPlayer } from 'components/Player/VideoPlayer/VideoPlayer';
import { LessonSurvey } from 'components/Player/LessonSurvey';
import { TableOfContents } from 'components/Player/TableOfContents/TableOfContents';
import { QuestionAndStudentInputs } from 'components/Player/QuestionAndStudentInputs';
import { Transcripts } from 'components/Player/Transcripts/Transcripts';
import { PanelAreaProvider, Panels, usePanelAreaContext } from 'components/Player/PanelAreaContext';
import { useClassroomStudentValidator } from 'components/GoogleClassroomStudentValidator';
import { useIntercom } from 'react-use-intercom';
import { KyronEvents } from '../utils/KyronEvents';
import { buildSegmentVideoUrlWithDefiniteEnding, buildSegmentVideoURL, getSearchParamStr } from '../utils/urlUtils';
import { useRemoveIntercom } from '../utils/KyronIntercom';
import { useUserContext } from '../UserContext';
import { LoadingIndicator } from '../LoadingIndicator';
import { ClassroomCredentials, useClassroomCredentials } from '../../controllers/react-query/classroomCredentialsHooks';
import { StudentProgress } from './StudentProgress/StudentProgress';
import { SegmentVideoAlert, useSegmentVideoAlertProps } from './SegmentVideoAlert';

const drawerWidth = 360;

const WorkspaceWrapperBox = styled(Box, { shouldForwardProp: (prop: string) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  paddingRight: 0,
  transition: theme.transitions.create('padding-right', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('padding-right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingRight: `${drawerWidth}px`,
  }),
}));

const PanelDrawer = styled(Drawer)(() => ({
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    padding: '0 16px 0 0',
    bgcolor: 'transparent',
    border: 'none',
    overflow: 'hidden',
    position: 'absolute',
    height: 'calc(100% - 64px)',
  },
}));

type PlayerProps = {
  lessonId?: number | string;
  restart?: boolean;
};

const PlayerInternal = ({ lessonId: lessonIdFromProps, restart: restartFromProps }: PlayerProps) => {
  const { showSpace } = useIntercom();

  const { user } = useUserContext();
  const { lessonId: lessonIdFromURLParams, segmentId } = useParams();
  const lessonId = lessonIdFromURLParams || lessonIdFromProps; // This enables <Player /> to be used as standalone component
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const ltiLaunch = search.get('lti_launch');
  const scorm = search.get('scorm');
  const videoScreen = useFullScreenHandle();
  const restart = Boolean(search.get('restart') || restartFromProps);
  const [runningLessonSegment, setRunningLessonSegment] = useState<LessonSegment | null>(null);
  const [runningLessonSegmentInstanceId, setRunningLessonSegmentInstanceId] = useState(0);
  const [runningLessonSessionId, setRunningLessonSessionId] = useState(0);
  const [runningLessonInstanceId, setRunningLessonInstanceId] = useState(0);
  const [tableOfContents, setTableOfContents] = useState<TOCItem[]>([]);
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const chatId = useRef(0);
  const [completed, setCompleted] = useState(false);
  const waitTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { googleClassroomData, isStudentEnrolled } = useClassroomCredentials();
  const { enqueueSnackbar } = useSnackbar();
  const [videoFileName, setVideoFileName] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const isWaitSegment = runningLessonSegment?.segment_type === 'wait';
  const {
    panelAreaOpen,
    setPanelAreaOpen,
    selectedPanelAreaButton,
    setSelectedPanelAreaButton,
    selectedPanel,
    setSelectedPanel,
  } = usePanelAreaContext();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  function handleVideoSettings(lessonSegment: LessonSegment) {
    if (videoFileName !== lessonSegment.video_filename) {
      const urlWithParams = buildSegmentVideoUrlWithDefiniteEnding(lessonSegment);
      setVideoUrl(urlWithParams);
      setVideoFileName(lessonSegment.video_filename);
    }
  }

  function handleNextSegment() {
    const nextSegmentForm: NextLessonSegmentForm = {
      lesson_segment_instance_id: runningLessonSegmentInstanceId,
    };
    return handleNextLessonSegment(nextSegmentForm);
  }

  type ScormRequesterData = {
    learnerId: string;
    learnerName: string;
    origin: string;
  };
  // Send a message to the parent window to initialize the connection and to
  // listen for the learnerId from the SCORM package. Also, get the origin of the
  // event so we know what LMS is talking to us.
  async function getScormRequesterData() {
    return new Promise<ScormRequesterData>(resolve => {
      window.parent.postMessage({ type: 'creatingLessonInstance' }, '*');
      window.addEventListener('message', event => {
        if (event.data.type === 'learnerInfo') {
          const { learnerId, learnerName } = event.data;
          resolve({ learnerId, learnerName, origin: event.origin });
        }
      });
    });
  }

  // fetch lesson data
  async function getLesson(id: number, classCreds: ClassroomCredentials) {
    if (!Number(id)) throw Error('Got an insufficient lesson ID to start the lesson');

    // If this player component is being rendered as a part of the SCORM iframe, we
    // need to get the learnerId from the SCORM package before we create the lesson
    // instance -- that way we can make sure to get the correct lesson instance (in
    // case the user has already started the lesson). So, we will wait for the learnerId
    // to come back via post messages.
    let scormExternalUserId;
    let scormUserName;
    let scormOrigin;
    if (scorm) {
      try {
        const { learnerId, learnerName, origin } = await getScormRequesterData();
        scormExternalUserId = learnerId;
        scormUserName = learnerName;
        scormOrigin = origin;
      } catch (error) {
        console.error('Error receiving learnerId from SCORM package');
      }
    }

    try {
      const response = await createLessonInstance(
        id,
        Number(segmentId),
        isStudentEnrolled ? Number(classCreds.classroomId) : 0,
        isStudentEnrolled ? 'Classroom' : '',
        restart,
        ltiLaunch,
        scormExternalUserId,
        scormUserName,
        scormOrigin,
      );
      setRunningLessonSegment(response.lesson_segment);
      setRunningLessonSegmentInstanceId(response.lesson_segment_instance.id);
      setRunningLessonSessionId(Number(response.lesson_instance.session_id));
      setRunningLessonInstanceId(response.lesson_instance.id);
      setTableOfContents(response.table_of_contents);
      setLesson(response.lesson);
      chatId.current = response.chat_instance_id;
      setVideoUrl(
        buildSegmentVideoURL(
          response.lesson_segment.video_url || '',
          response.lesson_segment.start_time,
          response.lesson_segment.end_time,
        ),
      );

      // send events
      KyronEvents.resetOptions();
      KyronEvents.addOptions({
        user_id: user?.id || 'anonymous',
        lesson_id: response.lesson.id,
        lesson_name: response.lesson.name,
        creator_name: response.lesson.tutor?.display_name,
        lesson_instance_id: response.lesson_instance.id,
        session_id: response.lesson_instance.session_id,
        lti_launch: ltiLaunch,
      });
      KyronEvents.sendEvent(KyronEvents.names.START_COURSE);
    } catch (error) {
      if (error instanceof Error) console.error('Player Lesson Start Error: ', error.message);
    }
  }

  useEffect(() => {
    if (!lessonId || !Number(lessonId)) return;
    getLesson(Number(lessonId), googleClassroomData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId, googleClassroomData?.status]);

  function handleLessonSegmentResponse(response: GotoLessonInstanceResponse | NextLessonSegmentResponse) {
    chatId.current = response.chat_instance_id;
    setRunningLessonSegmentInstanceId(response.lesson_segment_instance.id);
    const lessonSegment: LessonSegment = response.lesson_segment;
    setRunningLessonSegment(lessonSegment);
    if (lessonSegment.video_filename !== null) handleVideoSettings(lessonSegment);
  }

  async function handleTOCClick(tableOfContent: TOCItem) {
    try {
      const response: GotoLessonInstanceResponse = await gotoLessonSegment(runningLessonInstanceId, tableOfContent);
      handleLessonSegmentResponse(response);
    } catch (error) {
      if (error instanceof Error) console.error('StartLessonSegment Error: ', error.message);
    }
  }

  async function handleNextLessonSegment(lessonInstanceForm: NextLessonSegmentForm) {
    try {
      const response: NextLessonSegmentResponse = await nextLessonSegment(runningLessonInstanceId, lessonInstanceForm);
      if (response.completed) {
        /* this generates a DOM Error that the video is playing that
            we need to deal with */
        enqueueSnackbar('Lesson Completed. Congratulations.');
        KyronEvents.sendEvent(KyronEvents.names.COMPLETE_COURSE);
        KyronEvents.resetOptions();
        setCompleted(true);
      } else {
        handleLessonSegmentResponse(response);
      }
      if (response.no_input_retry !== null) {
        enqueueSnackbar('No input detected. Please try again.', { variant: 'warning' });
      }
      return response;
    } catch (error) {
      if (error instanceof Error) enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  const changeLesson = (id: number) => {
    if (!id) {
      enqueueSnackbar('Cannot switch tutor: Lesson could not be found.');
      return;
    }
    // Get the new lesson
    getLesson(id, googleClassroomData);

    // Handle the state updates
    // -- Close the TOC
    setPanelAreaOpen(false);
    // -- Update lesson ID in URL
    // ---- NO a re-render
    // ---- NO new entry in history (so that back button goes to the right place)
    navigate(`/video_player/${id}${getSearchParamStr(search)}`, { replace: true });
  };

  function setWaitTimeout(handleWaitTimeout: () => void) {
    if (waitTimeoutId.current !== null) return;
    if (runningLessonSegment !== null && runningLessonSegment.wait_timeout > 0) {
      waitTimeoutId.current = setTimeout(() => handleWaitTimeout(), runningLessonSegment.wait_timeout * 1000);
    }
  }

  function cancelWaitTimeout() {
    if (waitTimeoutId.current !== null) {
      clearTimeout(waitTimeoutId.current);
      waitTimeoutId.current = null;
    }
  }

  const feedbackForm = (
    <WorkspaceWrapperBox open={false}>
      <LessonSurvey
        sessionId={runningLessonSessionId?.toString() || ''}
        lesson={lesson}
        instanceId={runningLessonInstanceId?.toString() || ''}
        user={user}
      />
    </WorkspaceWrapperBox>
  );

  function togglePanel(event: React.MouseEvent<HTMLElement>, value: Panels | null) {
    if (value) {
      setSelectedPanel(value);
      setSelectedPanelAreaButton(value);
      setPanelAreaOpen(true);
    } else {
      setSelectedPanelAreaButton(null);
      setPanelAreaOpen(false);
    }
  }

  const segmentVideoAlertProps = useSegmentVideoAlertProps(runningLessonSegment);

  function handleIframeScorePosting() {
    // CAUTION: ANY ORIGIN WILL RECEIVE THIS MESSAGE (targetOrigin: '*') -
    // DO NOT ADD PRIVATE INFORMATION
    // The purpose is to send the relevant information to the LMS, but any
    // platform that renders Kyron will receive this message
    window.parent.postMessage(
      { type: 'score', score: { rawScore: 100, maxScore: 100, completionStatus: 'completed' } },
      '*',
    );
  }

  if (completed) {
    handleIframeScorePosting();
    return feedbackForm;
  }

  function getVideoFlex() {
    if (isMobile) return '0 0 auto';
    return isWaitSegment ? '1 0 50%' : '1 0 0';
  }

  const clickedTocEvent = () => {
    KyronEvents.sendEvent(KyronEvents.names.TOGGLE_TOC, {
      user_id: user?.id || 'anonymous',
      lesson_id: lesson?.id || 0,
      lesson_instance_id: runningLessonInstanceId,
      session_id: runningLessonSessionId,
    });
  };

  const clickedTranscriptEvent = () => {
    KyronEvents.sendEvent(KyronEvents.names.TOGGLE_TRANSCRIPT, {
      user_id: user?.id || 'anonymous',
      lesson_id: lesson?.id || 0,
      lesson_instance_id: runningLessonInstanceId,
      session_id: runningLessonSessionId,
    });
  };

  return (
    <>
      {segmentVideoAlertProps && <SegmentVideoAlert {...segmentVideoAlertProps} />}

      <Box sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}>
        <FullScreen handle={videoScreen}>
          <WorkspaceWrapperBox open={panelAreaOpen && !videoScreen.active}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '100%' }}>
              <Box
                sx={{
                  flex: getVideoFlex(),
                  px: isMobile ? 0 : 2,
                  overflow: 'hidden',
                  transition: 'flex .4s ease-in-out',
                }}
              >
                {runningLessonSegment && !segmentVideoAlertProps && (
                  <VideoPlayer
                    key={runningLessonSessionId} // very important to have - if missing, sometimes video player's state won't update
                    sessionId={runningLessonSessionId}
                    runningLessonSegment={runningLessonSegment}
                    segmentId={runningLessonSegmentInstanceId}
                    handleNextSegment={handleNextSegment}
                    setWaitTimeout={setWaitTimeout}
                    cancelWaitTimeout={cancelWaitTimeout}
                    videoUrl={videoUrl}
                    sx={{
                      aspectRatio: '16/9',
                      maxHeight: '100%',
                      margin: '0 auto',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                )}
              </Box>
              <Box
                flex={isWaitSegment ? '1 0 50%' : '0 0 64px'}
                minHeight={0}
                sx={{ transition: 'flex .4s ease-in-out' }}
              >
                <QuestionAndStudentInputs
                  lessonSegment={runningLessonSegment as never}
                  handleNextLessonSegment={handleNextLessonSegment}
                  chatId={chatId.current}
                  sessionId={runningLessonSessionId}
                  segmentInstanceId={runningLessonSegmentInstanceId}
                  cancelWaitTimeout={cancelWaitTimeout}
                />
              </Box>
            </Box>
          </WorkspaceWrapperBox>
        </FullScreen>

        {isMobile ? null : (
          <>
            <StudentProgress lesson={lesson} lessonSegment={runningLessonSegment} />
            <ToggleButtonGroup
              exclusive
              value={selectedPanelAreaButton}
              onChange={togglePanel}
              aria-label='toggle panel'
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                px: 2,
                height: '64px',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Tooltip placement='top' title='Help'>
                <IconButton
                  onClick={() => showSpace('home')}
                  sx={{ border: '1px solid', borderColor: 'primary.main', color: 'primary.main', p: '11px' }}
                >
                  <HelpOutline />
                </IconButton>
              </Tooltip>
              <Tooltip placement='top' title='Lesson Info'>
                <ToggleButton
                  variant='iconButton'
                  value={Panels.INFO}
                  data-testid='toggle-info-panel'
                  aria-label='lesson info panel'
                  onClick={clickedTocEvent}
                >
                  <InfoOutlined />
                </ToggleButton>
              </Tooltip>

              <Tooltip placement='top' title='Transcript'>
                <ToggleButton
                  variant='iconButton'
                  value={Panels.TRANSCRIPT}
                  data-testid='toggle-transcript-panel'
                  aria-label='transcript panel'
                  onClick={clickedTranscriptEvent}
                >
                  <TextSnippet />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </>
        )}

        <PanelDrawer open={panelAreaOpen} variant='persistent' anchor='right'>
          {selectedPanel === Panels.INFO && (
            <TableOfContents
              tableOfContents={tableOfContents}
              lesson={lesson!}
              handleTOCItemClick={handleTOCClick}
              changeLesson={changeLesson}
              runningLessonSegmentId={runningLessonSegment?.id}
            />
          )}
          {selectedPanel === Panels.TRANSCRIPT && <Transcripts transcript={runningLessonSegment?.transcript || ''} />}
        </PanelDrawer>
      </Box>
    </>
  );
};

export const Player = ({ lessonId, restart }: PlayerProps) => {
  useRemoveIntercom();

  const { signInDialog, shouldRequireGoogleLogin, isFetchingGoogleClassroomData, googleClassroomData } =
    useClassroomStudentValidator({
      showUIMessages: true,
    });

  if (!googleClassroomData || isFetchingGoogleClassroomData)
    return <LoadingIndicator message='Hang tight! Loading your lesson...' overlay />;
  // If the user is unauthorized, show the sign in dialog and do not render the player before the user is authorized
  // This is because we need the current_user to be available (user must be signed in) before we can render the player
  if (shouldRequireGoogleLogin) return signInDialog;

  return (
    <PanelAreaProvider>
      <PlayerInternal lessonId={lessonId} restart={restart} />
    </PanelAreaProvider>
  );
};
