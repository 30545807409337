import React from 'react';
import { Alert, AlertTitle, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { useCanvasCourse, useLinkCanvasCourse } from 'controllers/react-query/canvasHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Add } from '@mui/icons-material';
import { useRedirectIfFeatureDisabled } from 'components/FeaturesContext';
import { DetailedSegmentType, Lesson } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { KyronMenu } from 'components/KyronMenu';
import { KyronMenuItem } from 'components/KyronMenuItem';

export function CanvasListModules() {
  useRedirectIfFeatureDisabled({ feature: 'show_canvas_courses', redirectTo: '/' });

  const { courseId } = useParams();
  const { data: course, isError, isLoading } = useCanvasCourse(courseId!);
  const { mutate: linkModule, isPending } = useLinkCanvasCourse();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (isLoading) {
    return <LoadingIndicator spinnerSize={20} sx={{ px: 1 }} message='Fetching course...' />;
  }
  if (isError || !course) {
    return <Alert severity='error'>Error fetching Canvas course modules</Alert>;
  }

  function enhanceModule(moduleId: string, emdStyle: DetailedSegmentType) {
    linkModule(
      {
        payload: {
          moduleId,
          courseId: course!.id,
          emdStyle,
        },
      },
      {
        onSuccess: (newLesson: Lesson) => {
          navigate(`/studio/courses/${newLesson.id}`);
        },
        onError: () => {
          enqueueSnackbar(`Error generating enhancement for module ${moduleId}`, { variant: 'error' });
        },
      },
    );
  }

  const conversationMenu = (moduleId: string, moduleName: string) => (
    <KyronMenu
      menuIcon={
        <Tooltip title={`Enhance ${moduleName}`}>
          <Add />
        </Tooltip>
      }
    >
      <KyronMenuItem
        label='Enhance with a Guided Conversation'
        onClick={() => enhanceModule(moduleId, 'exploratory')}
      />
      <KyronMenuItem
        label='Enhance with a Reflective Conversation'
        onClick={() => enhanceModule(moduleId, 'reflection')}
      />
    </KyronMenu>
  );

  return (
    <>
      <h1>{course.name}</h1>
      {isPending && (
        <Alert severity='info'>
          <AlertTitle>Creating an enhancement for your Canvas course...</AlertTitle>
        </Alert>
      )}
      <Table sx={{ typography: 'bodyMedium' }}>
        <TableHead>
          <TableRow>
            <TableCell>Module Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {course.modules.map(module => (
            <TableRow>
              <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {conversationMenu(module.id, module.name)}
                {module.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
