import React from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { VideoPreview } from 'components/LessonSegmentEditor/VideoPreview';
import { LessonSegment, SegmentMedia } from 'controllers/types';
import { useUpdateSegment } from 'controllers/react-query';
import { LoadingIndicator } from 'components/LoadingIndicator';

type SegmentMediaDisplayProps = {
  isPending: boolean;
  segment: LessonSegment;
  selectedMediaType: SegmentMedia | null;
};

export function SegmentMediaDisplay({ isPending, segment, selectedMediaType }: SegmentMediaDisplayProps) {
  const { mutateAsync: updateSegment, isPending: isUpdatingSegment } = useUpdateSegment({ segmentId: segment.id });
  const { video_url: videoUrl, image_url: imageUrl, video_vtt_url: vttUrl } = segment || {};

  async function handleGenerateVideo() {
    try {
      await updateSegment({
        lessonId: segment.lesson_id,
        payload: { ...segment, media_type: selectedMediaType, generateVideo: true },
      });
    } catch (e) {
      console.error('Failed to request new video generation', e);
    }
  }

  const getMediaAction = () => {
    if (isPending) return null;

    const isKyronvideo = selectedMediaType === SegmentMedia.KYRON_VIDEO;
    const isUserImageWithAudio = selectedMediaType === SegmentMedia.USER_IMAGE_WITH_AUDIO;

    if (isKyronvideo || (isUserImageWithAudio && imageUrl)) {
      return (
        <Button
          data-testid='generate-video-button'
          onClick={handleGenerateVideo}
          startIcon={isUpdatingSegment ? <LoadingIndicator /> : <Refresh />}
          disabled={isUpdatingSegment}
          size='small'
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: 'scrim',
          }}
        >
          {videoUrl ? 'Regenerate Video' : 'Generate Video'}
        </Button>
      );
    }

    // Additional actions here
    return null;
  };

  const getMediaComponent = () => {
    if (isPending) {
      return <Skeleton data-testid='video-skeleton' variant='rectangular' sx={{ height: '100%', width: '100%' }} />;
    }
    // Placeholder for when there is no video
    if (!videoUrl) {
      return (
        <Box data-testid='video-placeholder' sx={{ bgcolor: 'primaryContainer.main', height: '100%', width: '100%' }} />
      );
    }

    return <VideoPreview url={videoUrl} vttUrl={vttUrl} key={videoUrl} start={0} />;
  };

  return (
    <Box
      data-testid='segment-media-display'
      sx={{
        flex: '1',
        aspectRatio: '16/9',
        borderRadius: 3,
        overflow: 'hidden',
        minWidth: '200px',
        position: 'relative',
      }}
    >
      {getMediaComponent()}
      {getMediaAction()}
    </Box>
  );
}
