/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Box, Collapse, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { KyronClient } from '../utils/KyronClient';
import { AllUnits } from './AllUnits';
import { NewUnit } from './NewUnit';
import { sortById, sortByPosition, moveObject } from '../utils/arrayUtils';

const styles = {
  Unitdiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  Unitbuttondiv: {
    margin: '10px 0px',
  },
  Unitcollapse: {
    width: '50%',
  },
  PositionButton: {
    margin: '0px 5px',
  },
};

export const UnitEditor = () => {
  const { enqueueSnackbar } = useSnackbar();
  const client = new KyronClient();
  const [units, setUnits] = useState([]);
  const [courses, setCourses] = useState([]);
  const [lessonCollections, setLessonCollections] = useState([]);
  const [newUnitExpanded, setNewUnitExpanded] = useState(false);
  const [lessons, setLessons] = useState([]);

  const handleNewUnitExpandClick = () => {
    setNewUnitExpanded(!newUnitExpanded);
  };

  const handleNewUnitFormSubmit = (_handleUnitUpdate, addUnit, unit) => {
    const formData = new FormData();
    for (const property in unit) {
      if (property !== 'id') {
        if (property === 'course') {
          formData.append('parent_id', unit[property].id);
        } else {
          formData.append(property, unit[property]);
        }
      }
    }

    client
      .submitJsonDataWithError('/api/v1/lesson_containers', 'POST', formData)
      .then(newUnit => {
        addUnit(newUnit);
        enqueueSnackbar('Unit Created');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const sortByPositionAndConcat = (list, onAttribute = 'children') =>
    list.reduce((newList, item) => {
      const newItem = item;
      const unsortedList = newItem[onAttribute];
      sortByPosition(unsortedList);
      return newList.concat(unsortedList);
    }, []);

  useEffect(() => {
    client
      .getDataWithError('/api/v1/lesson_containers/get_tiered_containers.json')
      .then(courseList => {
        const unitList = sortByPositionAndConcat(courseList);
        const lessonCollectionList = sortByPositionAndConcat(unitList, 'lesson_collections');
        setCourses(courseList);
        setUnits(unitList);
        setLessonCollections(lessonCollectionList);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    client
      // HACK: Request lots of lessons because we don't handle pagination very
      // well and we're close to hitting the 100 lesson default limit.
      .getDataWithError('/api/v1/lessons.json?per_page=200&active=true')
      .then(data => {
        setLessons(data.lessons);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewUnit = newUnit => {
    setUnits(units.concat(newUnit));
  };

  const handleUnitDelete = async id => {
    const unit = units.find(u => u.id === id);
    if (unit.children?.length > 0 || unit.lesson_collections?.length > 0) {
      enqueueSnackbar('Unit cannot be deleted because it has contents', { variant: 'error' });
    }
    try {
      await client.deleteDataWithError(`/api/v1/lesson_containers/${id}`);
      deleteUnit(id);
      enqueueSnackbar('Unit Deleted');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  function deleteUnit(id) {
    const newUnits = units.filter(unit => unit.id !== id);
    setUnits(newUnits);
  }

  const handleUnitUpdate = async (updateUnit, unit) => {
    const formData = new FormData();
    for (const property in unit) {
      // TODO (Harish): Remove lessons check eventually.
      if (property !== 'id' && property !== 'lessons') {
        if (property === 'course') {
          formData.append('parent_id', unit[property].id);
        } else {
          formData.append(property, unit[property]);
        }
      }
    }

    try {
      const updatedUnit = await client.submitJsonDataWithError(`/api/v1/lesson_containers/${unit.id}`, 'PUT', formData);
      updateUnit(updatedUnit);
      enqueueSnackbar('Unit Updated');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const updateUnit = updatedUnit => {
    const newUnits = units.filter(unit => unit.id !== updatedUnit.id);
    newUnits.push(updatedUnit);
    sortByPosition(newUnits);
    setUnits(newUnits);
  };

  const updateLesson = (updatedLesson, oldParentId) => {
    const newLessons = lessons.filter(lesson => lesson.id !== updatedLesson.id);
    newLessons.push(updatedLesson);
    setLessons(newLessons);

    // remove from the old lesson_collection
    const collection = lessonCollections.find(col => col.id === oldParentId);
    const newLessonList = collection.lessons.filter(lesson => lesson.id !== updatedLesson.id);
    collection.lessons = newLessonList;
    updateCollection(collection);

    // add to the newly chosen lesson_collection
    const newLessonCollection = lessonCollections.find(c => c.id === updatedLesson.lesson_collection.id);
    newLessonCollection.lessons.push(updatedLesson);
    const newCollections = lessonCollections.filter(c => c.id !== newLessonCollection.id);
    newCollections.push(newLessonCollection);
    sortByPosition(newCollections);
    setLessonCollections(newCollections);
  };

  const handleLessonUpdate = (lesson, oldContainerId, newContainerId) => {
    const formData = new FormData();
    formData.append('lesson_collection_id', newContainerId);

    client
      .submitJsonDataWithError(`/api/v1/lessons/${lesson.id}`, 'PUT', formData)
      .then(updatedLesson => {
        updateLesson(updatedLesson, oldContainerId);
        enqueueSnackbar('Unit Updated');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleLessonRemoveFromCollection = (lesson, parentId) => {
    const formData = new FormData();
    formData.append('lesson_collection_id', 0);

    client
      .submitJsonDataWithError(`/api/v1/lessons/${lesson.id}`, 'PUT', formData)
      .then(updatedLesson => {
        removeLesson(updatedLesson, parentId);
        enqueueSnackbar('Unit Updated');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  function removeLesson(lesson, parentId) {
    if (units.map(unit => unit.id).includes(parentId)) {
      const unit = units.find(u => u.id === parentId);
      unit.lessons = unit.lessons.filter(unitLesson => unitLesson.id !== lesson.id);
      setUnits(units);
    } else {
      const collection = lessonCollections.find(col => col.id === parentId);
      collection.lessons = collection.lessons.filter(collectionLesson => collectionLesson.id !== lesson.id);
      setLessonCollections(lessonCollections);
    }
    const newLessons = lessons.filter(l => l.id !== lesson.id);
    newLessons.push(lesson);
    sortById(newLessons);
    setLessons(newLessons);
  }

  const handleLessonAdd = (lesson, collectionId) => {
    const formData = new FormData();
    formData.append('lesson_collection_id', collectionId);

    client
      .submitJsonDataWithError(`/api/v1/lessons/${lesson.id}`, 'PUT', formData)
      .then(updatedLesson => {
        const lessonCollection = lessonCollections.find(collection => collection.id === collectionId);
        lessonCollection.lessons.push(updatedLesson);
        setLessonCollections(lessonCollections);

        const newLessons = lessons.filter(l => l.id !== updatedLesson.id);
        newLessons.push(updatedLesson);
        setLessons(newLessons);
        enqueueSnackbar('Unit Updated');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  // TODO: Need to clean this up and make it as a react-query
  const handleAddCollection = async (collection, unitId) => {
    const formData = new FormData();
    // eslint-disable-next-line guard-for-in
    for (const property in collection) {
      formData.append(property, collection[property]);
    }
    try {
      const updatedCollection = await client.submitJsonDataWithError('/api/v1/lesson_collections', 'POST', formData);
      addCollectionToUnit(updatedCollection, unitId);
      enqueueSnackbar('Unit Updated');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  function addCollectionToUnit(collection, unitId) {
    const newUnit = units.find(u => u.id === unitId);
    newUnit.children.push(collection);
    updateUnit(newUnit);
    lessonCollections.push(collection);
    setLessonCollections(lessonCollections);
  }

  const getAPIForCollection = collection => `/api/v1/lesson_collections/${collection.id}`;

  // TODO: Need to clean this up and make it as a react-query
  const handleCollectionDelete = async collection => {
    if (collection.children?.length > 0 || collection.lessons?.length > 0) {
      enqueueSnackbar('Collection cannot be deleted because it has contents', 'error');
      return;
    }
    try {
      await client.deleteDataWithError(getAPIForCollection(collection));
      deleteCollection(collection);
      enqueueSnackbar('Collection Deleted');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  function deleteCollection(collection) {
    const newUnit = units.find(unit => unit.id === collection.parent_id);
    const newCollections = newUnit.lesson_collections.filter(c => c.id !== collection.id);
    newUnit.lesson_collections = newCollections;
    updateUnit(newUnit);
    const newLessonCollections = lessonCollections.filter(c => c.id !== collection.id);
    setLessonCollections(newLessonCollections);
  }

  // TODO: Need to clean this up and make it as a react-query
  const handleCollectionUpdate = async collection => {
    const formData = new FormData();
    // eslint-disable-next-line guard-for-in
    for (const property in collection) {
      formData.append(property, collection[property]);
    }

    try {
      const updatedCollection = await client.submitJsonDataWithError(getAPIForCollection(collection), 'PUT', formData);
      updateCollection(updatedCollection);
      enqueueSnackbar('Collection Updated');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  function updateCollection(...collections) {
    const updatedCollectionIds = collections.map(c => c.id);
    const newCollections = lessonCollections.filter(c => !updatedCollectionIds.includes(c.id));
    newCollections.push(...collections);
    sortByPosition(newCollections);
    setLessonCollections(newCollections);
  }

  const reorderCollection = (collection, unitId, direction) => {
    let collections = lessonCollections.filter(c => c.parent_id === unitId);
    const newCollection = collections.find(c => c.id === collection.id);
    collections = moveObject(newCollection, collections, direction);
    collections.forEach((col, index) => {
      // eslint-disable-next-line no-param-reassign
      col.position = index + 1;
    });
    updateCollection(...collections);
    const newUnit = units.find(unit => unit.id === unitId);
    newUnit.lesson_collections = collections;
    updateUnit(newUnit);
  };

  const reorderLesson = (lesson, collectionId, direction) => {
    const lessonCollection = lessonCollections.find(collection => collection.id === collectionId);
    let { lessons: lessonList } = lessonCollection;
    const newLesson = lessonList.find(l => l.id === lesson.id);
    lessonList = moveObject(newLesson, lessonList, direction);
    lessonList.forEach((l, index) => {
      // eslint-disable-next-line no-param-reassign
      l.position = index + 1;
      updateLesson(lesson, collectionId);
    });
    lessonCollection.lessons = lessonList;
    if (units.find(unit => unit.id === collectionId)) {
      updateUnit(lessonCollection);
    } else {
      updateCollection(lessonCollection, lessonCollection.parent_id);
    }
  };

  async function handleUpdatePositionsClick() {
    const formData = new FormData();
    units.forEach(unit => {
      formData.append('lesson_containers[]', JSON.stringify(unit));
    });
    try {
      await client.submitJsonDataWithError('/api/v1/lesson_containers/update_positions', 'PUT', formData);
      enqueueSnackbar('Positions Saved');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useTitle('Unit Editor');
  useActions(
    <Box display='flex' justifyContent='flex-end'>
      <Button variant='contained' onClick={handleNewUnitExpandClick} icon={AddIcon}>
        New Unit
      </Button>
    </Box>,
  );

  return (
    <>
      <Box display='flex' justifyContent='flex-end'>
        <Button variant='contained' onClick={handleUpdatePositionsClick} style={styles.PositionButton}>
          Save Positions
        </Button>
      </Box>
      <div style={styles.Unitbuttondiv}>
        <div style={styles.Unitdiv}>
          <Collapse in={newUnitExpanded} style={styles.Unitcollapse} timeout='auto' unmountOnExit>
            <NewUnit
              addUnit={addNewUnit}
              handleFormSubmit={handleNewUnitFormSubmit}
              handleFormCancel={handleNewUnitExpandClick}
              handleUnitUpdate={handleUnitUpdate}
              courses={courses}
            />
          </Collapse>
        </div>
      </div>
      <AllUnits
        units={units}
        lessonCollections={lessonCollections}
        handleUnitDelete={handleUnitDelete}
        handleUnitUpdate={handleUnitUpdate}
        updateUnit={updateUnit}
        courses={courses}
        lessons={lessons}
        handleLessonAdd={handleLessonAdd}
        handleLessonUpdate={handleLessonUpdate}
        handleLessonRemove={handleLessonRemoveFromCollection}
        handleAddCollection={handleAddCollection}
        handleCollectionDelete={handleCollectionDelete}
        handleCollectionUpdate={handleCollectionUpdate}
        reorderCollection={reorderCollection}
        reorderLesson={reorderLesson}
      />
    </>
  );
};
