import React from 'react';
import { useStudentResponseLogsQuery } from 'controllers/react-query/lessonInstanceHooks';
import { Stack, IconButton, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { PlayCircleOutline, ExpandMore, Warning } from '@mui/icons-material';
import { StudentResponseRow } from 'controllers/types';
import { ValueFormatterParams } from 'ag-grid-community';
import { DataGrid } from 'components/common/DataGrid';
import { useTheme } from '@mui/material/styles';

const styles = {
  warningTooltip: {
    marginRight: '10px',
  },
};

type Props = {
  lessonInstanceId: number;
};

export const StudentResponseLogs = ({ lessonInstanceId }: Props) => {
  const { isFetching, isError, data } = useStudentResponseLogsQuery(lessonInstanceId);
  const theme = useTheme();

  const createStudentInstanceRows = () => {
    if (isFetching) return; // return undefined so the dataGrid renders the loading state
    if (isError) {
      return []; // return empty array so the dataGrid renders an empty table instead of continuing the loading state
    }
    return data;
  };

  const questionRenderer = (params: ValueFormatterParams<unknown, StudentResponseRow['question']>) => {
    if (!params.value) return null;
    const url = `/video_player/${params.value.lesson_id}/segments/${params.value.segment_id}`;
    return (
      <Stack direction='row' alignItems='center'>
        <Tooltip title='Play lesson from question'>
          <IconButton size='small' component='a' href={url} target='_blank' data-testid='play-lesson'>
            <PlayCircleOutline />
          </IconButton>
        </Tooltip>
        <Typography sx={{ mb: 1, mt: 1 }}>{params.value.question}</Typography>
      </Stack>
    );
  };

  const textRenderer = (params: ValueFormatterParams<unknown, StudentResponseRow['student_input']>) => {
    if (!params.value) return null;
    // OEQ Chat View
    if (params.value.chat.length > 0) {
      return (
        <Accordion sx={{ backgroundColor: 'transparent' }}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>View Chat</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction='column' spacing={1} sx={{ mb: 1, mt: 1 }}>
              {params.value.chat.map(message => (
                <Typography key={message.id}>
                  <b>{message.role}</b>: {message.content}
                </Typography>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      );
    }
    // Voice, Text, or MCQ View
    if (params.value.text)
      return (
        <Stack direction='row' alignItems='center'>
          {params.value.sensitive_reason && (
            <Tooltip title='Sensitive student response' sx={styles.warningTooltip}>
              <Warning />
            </Tooltip>
          )}
          <Typography sx={{ mb: 1, mt: 1 }}>{params.value.text}</Typography>
        </Stack>
      );
  };

  const studentResponseColumns = [
    {
      field: 'question',
      headerName: 'Question',
      wrapText: true,
      autoHeight: true,
      cellRenderer: questionRenderer,
      cellStyle: { display: 'flex', alignItems: 'center', wordBreak: 'normal' },
    },
    {
      field: 'student_input',
      headerName: 'Student Input',
      wrapText: true,
      autoHeight: true,
      cellRenderer: textRenderer,
      cellStyle: { display: 'flex', alignItems: 'center', wordBreak: 'normal' },
    },
  ];

  const noRowsText = isError
    ? 'Something went wrong. Could not load data.'
    : 'Student has not answered any questions yet';

  return (
    <DataGrid
      rows={createStudentInstanceRows()}
      columns={studentResponseColumns}
      noRowsText={noRowsText}
      getRowStyle={params =>
        params.data.student_input?.sensitive_reason ? { backgroundColor: theme.palette.errorContainer.main } : undefined
      }
      loading={isFetching}
    />
  );
};
