import { Lesson } from 'controllers/types';
import { PayloadAndRequestParams, useKyronMutationV2 } from './kyronMutation';

export type StructuredLearningObjectives = string[];

export type GenerateQuestionsPayload = {
  lessonTitle: string;
  lessonObjective: string;
  audience: string;
  structuredLearningObjectives: StructuredLearningObjectives;
  signedMaterialIds: string[];
  questionType: string | null;
  activityTemplate: string;
  ttsVoiceId: string;
  intendedTime: number;
};

export type RegenerateAllLessonVideosPayload = {
  lessonId: string | number | undefined;
};

export const useStructureLearningObjectives = () =>
  useKyronMutationV2<{ payload: GenerateQuestionsPayload }, StructuredLearningObjectives>(
    `/simple_lessons/structure_learning_objectives`,
    {
      method: 'POST',
    },
  );

export const useGenerateEntireLesson = () =>
  useKyronMutationV2<{ payload: Partial<GenerateQuestionsPayload> }, Lesson>(`/simple_lessons/generate_entire_lesson`, {
    invalidatesMultiple: [['/lessons'], ['/lesson_collections/library']],
  });

// EK: removing the invalidation of the lesson_segment here as it does not have the right
// effect. the invalidation needs to happen after the background_task completes vs. this API call completing
export const useRegenerateAllLessonVideos = () =>
  useKyronMutationV2<PayloadAndRequestParams & RegenerateAllLessonVideosPayload>(
    '/lessons/:lessonId/regenerate_all_lesson_videos',
    {
      invalidatesMultiple: ({ lessonId }: RegenerateAllLessonVideosPayload) => [
        [`/lessons/${lessonId}/lesson_details`],
        [`/lessons/${lessonId}`],
      ],
    },
  );
