import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline } from '@mui/material';
import { AppBar } from 'components/AppBar/AppBar';
import { BackButton } from 'components/BackButton/BackButton';
import { getTheme } from 'components/theme/kyronTheme';
import { useGetLessonCollection } from 'controllers/react-query/lessonCollectionHooks';
import { LessonCollection } from 'controllers/types';
import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

type ContextType = {
  lessonCollection: LessonCollection;
};

export function LessonLayout() {
  const { lessonCollectionId } = useParams();
  const { data: lessonCollection, isLoading, isError } = useGetLessonCollection(Number(lessonCollectionId));

  if (isLoading) return <div data-testid='lesson-collection-loading'>Loading...</div>;

  if (isError) throw new Error('An error occurred while fetching the lesson.');

  if (!lessonCollection) throw new Error('Lesson not found.');

  // TODO: Replace with an actual layout
  return (
    <ThemeProvider theme={getTheme('dark')}>
      <CssBaseline enableColorScheme />
      <AppBar title={lessonCollection.name} navButton={<BackButton />} />
      <Box
        component='main'
        sx={{ position: 'fixed', top: '64px', left: 0, bottom: 0, right: 0 }}
        data-testid='lesson-collection-layout'
      >
        <Outlet context={{ lessonCollection } satisfies ContextType} />
      </Box>
    </ThemeProvider>
  );
}

export function MinimalLessonLayout() {
  return (
    <ThemeProvider theme={getTheme('dark')}>
      <CssBaseline enableColorScheme />
      <Outlet />
    </ThemeProvider>
  );
}

export function useLessonCollection() {
  return useOutletContext<ContextType>();
}
