import { useEffect, useState } from 'react';

import { BackgroundTask, LessonSegment, MinimalLesson } from 'controllers/types';
import consumer from '../../channels/consumer';
import { devConsole } from '../utils/logUtils';
import { useLessonBTWatcher } from './useLessonBTWatcher';

// background_tasks with these statuses are monitored through a websocket
const pendingStatusStates = ['enqueued', 'started', 'waiting'];

type ChannelData = { status: BackgroundTask['status']; message: string };
export function useProgressBarChannel(task?: BackgroundTask, taskDoneFunction?: (data: BackgroundTask) => void) {
  const [taskData, setTaskData] = useState(task || ({} as BackgroundTask)); // empty object to allow consumer to use dot notation

  // disabling because we don't wanna run this effect on taskData changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setInitialTaskState, [task, task?.id, task?.status]);
  function setInitialTaskState() {
    if (task?.id !== taskData.id || task?.status !== taskData.status) {
      setTaskData(task !== undefined ? task : ({} as BackgroundTask));
    }
  }

  useEffect(() => {
    // background_tasks with these statuses are monitored through a websocket
    if (task !== undefined && pendingStatusStates.some(s => task?.status === s)) {
      const channel = consumer.subscriptions.create(
        {
          channel: 'ProgressBarChannel',
          task_id: task.id,
        },
        {
          received(data: ChannelData) {
            console.debug('useProgressBarChannel received', { taskName: task.name, ...data });
            const updatedTask = { ...taskData, ...data };
            setTaskData(updatedTask);
            if (taskDoneFunction) taskDoneFunction(updatedTask);
          },
        },
      );
      return () => {
        channel.unsubscribe();
        consumer.disconnect();
      };
    }
  }, [task, taskData, taskDoneFunction]);

  return taskData;
}

export function getPublishBackgroundJob(lesson: MinimalLesson | undefined | null) {
  if (lesson === null || lesson === undefined) return undefined;
  const taskNames = [`publish-lesson-${lesson.id}`];
  return getBackgroundJob(lesson.background_tasks, taskNames);
}

export function getGenerateLessonBackgroundJob(lesson: MinimalLesson | undefined | null) {
  if (lesson === null || lesson === undefined) return undefined;
  const taskNames = [`generate-entire-lesson-${lesson.id}`];
  return getBackgroundJob(lesson.background_tasks, taskNames);
}

export function getTranslateLessonBackgroundJob(lesson: MinimalLesson | undefined | null) {
  if (lesson === null || lesson === undefined) return undefined;
  const taskNames = [`translate-lesson-${lesson.id}`];
  return getBackgroundJob(lesson.background_tasks, taskNames);
}

// export for testing purposes
export function getBackgroundJob(backgroundTasks: BackgroundTask[], taskName: string[]) {
  const allProcessingTasks = backgroundTasks?.filter(t => taskName.includes(t.name));
  return allProcessingTasks?.sort((a, b) => b.id - a.id)[0];
}

export function getRegenerateAndNormalizeVideoBackgroundJob(lesson: MinimalLesson | undefined | null) {
  if (lesson === null || lesson === undefined) return undefined;
  const taskNames = [`regenerate-and-normalize-video-for-segment-${lesson.id}`];
  return getBackgroundJob(lesson.background_tasks, taskNames);
}

export function getGenerateVideoBackgroundJob(lessonId: number, backgroundTasks: BackgroundTask[] | undefined | null) {
  if (backgroundTasks === null || backgroundTasks === undefined) return undefined;
  const taskNames = [`generate-and-normalize-video-for-segment-${lessonId}`];
  return getBackgroundJob(backgroundTasks, taskNames);
}

export function useWatchSegmentBackgroundTasks({
  segment,
  onSuccess,
  onError,
}: {
  segment?: LessonSegment;
  onSuccess: () => void;
  onError: (err: string) => void;
}) {
  const tasks = segment?.background_tasks || [];
  const firstActiveTask = tasks.filter(task => task.status !== 'done' && task.status !== 'error')[0];
  const { status, message } = useProgressBarChannel(firstActiveTask, task => {
    if (task.status === 'done') {
      onSuccess();
    } else if (task.status === 'error') {
      onError(message || 'Unknown error');
    }
  });

  return { hasActiveBackgroundTask: isPendingStatus(status) };
}

export function isPendingStatus(status: string) {
  return pendingStatusStates.includes(status);
}

export const generateSafeBGTaskErrorText = (
  // TODO(ege): This argument is not really needed, I am adding it to not grow the scope of my work here.
  // Ideally, value of this argument should be derived from background task name. However, that requires going through
  // all the background task names to make them UI friendly and update all the watchers to watch new names.
  process: 'video generation' | 'publishing' | 'course creation' | 'translation',
  bgTask: BackgroundTask,
) => {
  const safeMessage = `Error during ${process}. -- Job: ${bgTask.id} (${bgTask.job_id}), Details: [${bgTask.task_entity_type}, ${bgTask.task_entity_id || bgTask.task_entity?.id}]`;
  // eslint-disable-next-line @typescript-eslint/naming-convention,camelcase
  const UNSAFE_privateMessage = bgTask.message; // bgTask.message is a private message that is only for our developers' eyes because it exposes our internals
  devConsole.error(safeMessage, UNSAFE_privateMessage);

  return safeMessage;
};

// Watch all the tasks that are running in the background for the DRAFT lesson.

// If a lesson has not finished publishing, then the translation task is running on
// the draft lesson. If a lesson has finished publishing, then the translation task is
// running on the published lesson. So, watch the translation task for both cases.
export const useWatchDraftLessonTasks = (lesson?: MinimalLesson) => {
  const publishJob = getPublishBackgroundJob(lesson);
  const translationJob = getTranslateLessonBackgroundJob(lesson);
  const publishTask = useProgressBarChannel(publishJob);
  const translationTask = useProgressBarChannel(translationJob);

  const { failed, isLessonPlanningInProgress } = useLessonBTWatcher(lesson?.id);

  let error = null;
  if (failed.lessonPlanning?.[0]) {
    error = generateSafeBGTaskErrorText('course creation', failed.lessonPlanning[0]);
  } else if (publishTask.status === 'error') {
    error = generateSafeBGTaskErrorText('publishing', publishTask);
  }

  return {
    isInProgress:
      isLessonPlanningInProgress || isPendingStatus(publishTask.status) || isPendingStatus(translationTask.status),
    error,
    warning: translationTask.status === 'error' ? generateSafeBGTaskErrorText('translation', translationTask) : null,
  };
};

// Watch all the tasks that are running in the background for the PUBLISHED lesson
// (at the moment, only translation task is monitored)
export const useWatchPublishedLessonTasks = (lesson?: MinimalLesson) => {
  const translationJob = getTranslateLessonBackgroundJob(lesson);
  const translationTask = useProgressBarChannel(translationJob);

  return {
    isInProgress: isPendingStatus(translationTask.status),
    warning: translationTask.status === 'error' ? generateSafeBGTaskErrorText('translation', translationTask) : null,
  };
};
