/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { LoaderFunctionArgs } from 'react-router-dom';
import { KyronClient } from '../components/utils/KyronClient';
import { Organization } from './types';

export async function getOrganizations({ request }: LoaderFunctionArgs): Promise<{
  organizations: Organization[];
  meta: { page: number; total_pages: number };
}> {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const perPage = url.searchParams.get('perPage') || 5;
  const client = new KyronClient();
  return await client.getDataWithError(`/api/v1/organizations?page=${page}&per_page=${perPage}`);
}

export function updateOrganization(organization: Organization): Promise<Organization> {
  const formData = new FormData();
  for (const property in organization) {
    const key = property as keyof Organization;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val = organization[key] as any;
    formData.append(property, val);
  }

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/organizations/${organization.id}`, 'PUT', formData);
}

export function createOrganization(organization: Organization): Promise<Organization> {
  const formData = new FormData();
  for (const property in organization) {
    const key = property as keyof Organization;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val = organization[key] as any;
    formData.append(property, val);
  }

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/organizations`, 'POST', formData);
}

/**
 * Set the active organization for the currently signed in user. This determines
 * what content the user can view and edit.
 * @param organizationId The ID of the new active organization.
 */
export function setActiveOrganization(organizationId: number): Promise<void> {
  const client = new KyronClient();
  return client.submitJsonDataWithError(
    `/api/v1/organizations/${organizationId}/set_active_organization`,
    'PUT',
    new FormData(),
  );
}
